"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _systemGroup = require("@/api/systemGroup");

var _clipboard = _interopRequireDefault(require("clipboard"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  name: "index",
  data: function data() {
    return {
      iframeUrl: "",
      menuList: [],
      menuInfo: {},
      typeName: "",
      list: [{
        name: "商城首页",
        url: "/pages/index/index",
        parameter: [{}],
        example: "/pages/index/index"
      }, {
        name: "个人中心",
        url: "/pages/user/index",
        parameter: [{}],
        example: "/pages/user/index"
      }, {
        name: "购物车",
        url: "/pages/order_addcart/order_addcart",
        parameter: [{}],
        example: "/pages/order_addcart/order_addcart"
      }, {
        name: "商品分类",
        url: "/pages/goods_cate/goods_cate",
        parameter: [{}],
        example: "/pages/goods_cate/goods_cate"
      }, {
        name: "商品详情",
        url: "/pages/goods_details/index",
        parameter: "id=商品ID",
        example: "/pages/goods_details/index?id=52"
      }, {
        name: "找回密码",
        url: "/pages/retrieve_password/index",
        parameter: [{}],
        example: "/pages/retrieve_password/index"
      }, {
        name: "商品列表",
        url: "/pages/goods_list/index",
        parameter: [{}],
        example: "/pages/goods_list/index"
      }, {
        name: "资讯",
        url: "/pages/news_list/index",
        parameter: [{}],
        example: "/pages/news_list/index"
      }, {
        name: "资讯详情",
        url: "/pages/news_details/index",
        parameter: 'id=新闻ID',
        example: "/pages/news_details/index?id=3"
      }, {
        name: "搜索商品",
        url: "/pages/goods_search/index",
        parameter: [{}],
        example: "/pages/goods_search/index"
      }, // {
      //   name: "支付状态",
      //   url: "/pages/order_pay_status/index",
      //   parameter: [{}],
      //   example: "/pages/order_pay_status/index",
      // },
      {
        name: "订单详情",
        url: "/pages/order_details/index",
        parameter: "order_id=订单ID",
        example: "/pages/order_details/index/?order_id=order123"
      }, {
        name: "隐私协议",
        url: "/pages/users/privacy/index",
        parameter: [{}],
        example: "/pages/users/privacy/index"
      }, {
        name: "客服",
        url: "/pages/users/web_page/index",
        parameter: [{}],
        example: "/pages/users/web_page/index"
      }, {
        name: "忘记密码",
        url: "/pages/users/retrievePassword/index",
        parameter: [{}],
        example: "/pages/users/retrievePassword/index"
      }, {
        name: "个人资料",
        url: "/pages/users/user_info/index",
        parameter: [{}],
        example: "/pages/users/user_info/index"
      }, {
        name: "领取优惠券",
        url: "/pages/users/user_get_coupon/index",
        parameter: [{}],
        example: "/pages/users/user_get_coupon/index"
      }, {
        name: "收藏商品",
        url: "/pages/users/user_goods_collection/index",
        parameter: [{}],
        example: "/pages/users/user_goods_collection/index"
      }, {
        name: "签到",
        url: "/pages/users/user_sgin/index",
        parameter: [{}],
        example: "/pages/users/user_sgin/index"
      }, {
        name: "签到记录",
        url: "/pages/users/user_sgin_list/index",
        parameter: [{}],
        example: "/pages/users/user_sgin_list/index"
      }, {
        name: "我的账户",
        url: "/pages/users/user_money/index",
        parameter: [{}],
        example: "/pages/users/user_money/index"
      }, {
        name: "账单明细",
        url: "/pages/users/user_bill/index",
        parameter: 'type=账单类型',
        example: "/pages/users/user_bill/index?type=all"
      }, {
        name: "积分详情",
        url: "/pages/users/user_integral/index",
        parameter: [{}],
        example: "/pages/users/user_integral/index"
      }, {
        name: "我的优惠券",
        url: "/pages/users/user_coupon/index",
        parameter: [{}],
        example: "/pages/users/user_coupon/index"
      }, {
        name: "我的推广",
        url: "/pages/users/user_spread_user/index",
        parameter: [{}],
        example: "/pages/users/user_spread_user/index"
      }, {
        name: "分销海报",
        url: "/pages/users/user_spread_code/index",
        parameter: [{}],
        example: "/pages/users/user_spread_code/index"
      }, {
        name: "佣金记录",
        url: "/pages/users/user_spread_money/index",
        parameter: 'type=类型&commissionCount=佣金金额',
        example: "/pages/users/user_spread_money/index?type=2&commissionCount=0.00"
      }, {
        name: "提现",
        url: "/pages/users/user_cash/index",
        parameter: [{}],
        example: "/pages/users/user_cash/index"
      }, {
        name: "地址管理",
        url: "/pages/users/user_address_list/index",
        parameter: [{}],
        example: "/pages/users/user_address_list/index"
      }, {
        name: "添加地址",
        url: "/pages/users/user_address/index",
        parameter: [{}],
        example: "/pages/users/user_address/index"
      }, {
        name: "修改手机号",
        url: "/pages/users/user_phone/index",
        parameter: [{}],
        example: "/pages/users/user_phone/index"
      }, {
        name: "余额充值",
        url: "/pages/users/user_payment/index",
        parameter: [{}],
        example: "/pages/users/user_payment/index"
      }, {
        name: "修改密码",
        url: "/pages/users/user_pwd_edit/index",
        parameter: [{}],
        example: "/pages/users/user_pwd_edit/index"
      }, {
        name: "提交订单",
        url: "/pages/users/order_confirm/index",
        parameter: 'preOrderNo=预订单号',
        example: "/pages/users/order_confirm/index?preOrderNo=1091627618511"
      }, {
        name: "门店列表",
        url: "/pages/users/goods_details_store/index",
        parameter: [{}],
        example: "/pages/users/goods_details_store/index"
      }, {
        name: "推广人列表",
        url: "/pages/users/promoter-list/index",
        parameter: [{}],
        example: "/pages/users/promoter-list/index"
      }, {
        name: "推广人订单",
        url: "/pages/users/promoter-order/index",
        parameter: [{}],
        example: "/pages/users/promoter-order/index"
      }, {
        name: "推广人排行",
        url: "/pages/users/promoter_rank/index",
        parameter: [{}],
        example: "/pages/users/promoter_rank/index"
      }, {
        name: "佣金排行",
        url: "/pages/users/commission_rank/index",
        parameter: [{}],
        example: "/pages/users/commission_rank/index"
      }, {
        name: "我的订单",
        url: "/pages/users/order_list/index",
        parameter: [{}],
        example: "/pages/users/order_list/index"
      }, {
        name: "物流信息",
        url: "/pages/users/goods_logistics/index",
        parameter: [{}],
        example: "/pages/users/goods_logistics/index"
      }, {
        name: "退货列表",
        url: "/pages/users/user_return_list/index",
        parameter: [{}],
        example: "/pages/users/user_return_list/index"
      }, {
        name: "申请退货",
        url: "/pages/users/goods_return/index",
        parameter: [{}],
        example: "/pages/users/goods_return/index"
      }, {
        name: "登录",
        url: "/pages/users/login/index",
        parameter: [{}],
        example: "/pages/users/promoter-list/index"
      }, {
        name: "商品评分",
        url: "/pages/users/goods_comment_list/index",
        parameter: 'productId=商品ID',
        example: "/pages/users/goods_comment_list/index?productId=69"
      }, {
        name: "商品评价",
        url: "/pages/users/goods_comment_con/index",
        parameter: 'unique=商品attrid&orderId=订单ID&id=ID',
        example: "/pages/users/goods_comment_con/index?unique=2032&orderId=order123&id=407"
      }, {
        name: "账户登录",
        url: "/pages/users/wechat_login/index",
        parameter: [{}],
        example: "/pages/users/wechat_login/index"
      }, {
        name: "绑定手机号",
        url: "/pages/users/app_login/index",
        parameter: [{}],
        example: "/pages/users/app_login/index"
      }],
      currentPage: "home",
      modelBanner: ["https://image.java.crmeb.net/crmebimage/maintain/2021/07/06/c99ee385e94d4711a0ea4be72169a86euwmzuhxbb2.jpg"],
      urlbg: require('@/assets/imgs/user_bg.png'),
      indexTab: [],
      dataList: [],
      addUrlStatus: true,
      infoStatus: false,
      showStatus: false,
      shows: 0,
      indextTabMenu: [],
      tabActive: 0,
      cate: false,
      tip: false,
      cateArr: [{
        img: require('@/assets/imgs/moren.png'),
        tit: '默认模板'
      }, {
        img: require('@/assets/imgs/youxuan.png'),
        tit: '模板1'
      }, {
        img: require('@/assets/imgs/haowu.png'),
        tit: '模板2'
      }, {
        img: require('@/assets/imgs/shengxian.png'),
        tit: '模板3'
      }],
      cateImg: '',
      active: 0,
      disabled: false,
      radio: "1"
    };
  },
  created: function created() {
    this.iframeUrl = "https://app.beta.java.crmeb.net?type=iframeVisualizing";
  },
  mounted: function mounted() {
    //监听子页面给当前页面传值
    window.addEventListener("message", this.handleMessage, false);
    this.designList();
    this.$set(this, 'typeName', '');
    this.$set(this, 'tip', true);
    this.$nextTick(function () {
      var _this2 = this;

      var clipboard = new _clipboard.default(".copy-data");
      clipboard.on("success", function () {
        _this2.$message.success("复制成功");
      });
    });
  },
  methods: {
    addBox: function addBox() {
      if (this.menuList.length >= 10 && this.typeName == 'indexMenu') {
        this.$message.warning("设置数据不能超过10条");
      } else if (this.typeName == 'indexTabNav' && this.menuList.length >= 4) {
        this.addUrlStatus = false;
        this.infoStatus = true;
        this.$message.warning("设置数据不能超过4条");
      } else {
        var indexMenu = JSON.parse(JSON.stringify(this.menuList[0]));
        indexMenu.id = null;
        indexMenu.name = "";
        indexMenu.url = "";
        indexMenu.info = "";
        indexMenu.pic = "";
        this.menuList.push(indexMenu);
      }
    },
    // 获取列表值；
    designList: function designList() {
      var _this3 = this;

      (0, _systemGroup.designListApi)().then(function (res) {
        _this3.menuInfo = res;
        var newArr = [];
        var indexMenu = res.indexMenu.filter(function (item, index, arr) {
          return item.status == true;
        });
        var indexBanner = res.indexBanner.filter(function (item, index, arr) {
          return item.status == true;
        });
        var userMenu = res.userMenu.filter(function (item, index, arr) {
          return item.status == true;
        });
        var indexNews = res.indexNews.filter(function (item, index, arr) {
          return item.status == true;
        });
        var userBanner = res.userBanner.filter(function (item, index, arr) {
          return item.status == true;
        });
        newArr.push(indexMenu, indexBanner, userMenu, indexNews, userBanner);
        _this3.dataList = newArr;
      });
      (0, _systemGroup.goodDesignList)({
        gid: 70
      }).then(function (response) {
        var list = response.list;
        var arr = [];
        var arr1 = [];
        list.forEach(function (item) {
          var obj = {};
          obj.value = JSON.parse(item.value);
          obj.id = item.id;
          obj.gid = item.gid;
          obj.status = item.status;
          arr.push(obj);
        });
        arr.forEach(function (item1) {
          var obj1 = {};
          obj1.pic = item1.value.fields[0].value;
          obj1.name = item1.value.fields[1].value;
          obj1.info = item1.value.fields[2].value;
          obj1.type = item1.value.fields[3].value;
          obj1.id = item1.id;
          obj1.gid = item1.gid; // obj1.show = '1';

          obj1.status = item1.status;
          arr1.push(obj1);
          _this3.indextTabMenu = arr1;
          var indexTab = arr1.filter(function (item, index, arr) {
            return item.status == true;
          });
          _this3.indexTab = indexTab;
        }); //
      });
    },
    //
    handleMessage: function handleMessage(event) {
      // this.typeName = event.data.name;
      this.typeName = event;

      switch (event) {
        case "indexMenu":
          this.menuList = this.menuInfo.indexMenu;
          this.shows = 3;
          break;

        case "indexBanner":
          this.menuList = this.menuInfo.indexBanner;
          this.shows = 1;
          break;

        case "userMenu":
          this.menuList = this.menuInfo.userMenu;
          this.shows = 6;
          break;

        case "indexNews":
          this.menuList = this.menuInfo.indexNews;
          this.shows = 2;
          break;

        case "userBanner":
          this.menuList = this.menuInfo.userBanner;
          this.shows = 5;
          break;

        case "indexTabNav":
          this.menuList = this.indextTabMenu;
          this.shows = 4;
      }
    },
    switchNav: function switchNav(index) {
      this.tabActive = index;
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i, boolean) {
      var _this = this;

      this.$modalUpload(function (img) {
        if (tit === "1" && num === "duo") {
          _this.menuList[i].pic = img[0].sattDir;
        }
      }, tit, "content", true);
    },
    // 删除
    bindDelete: function bindDelete(item, index) {
      this.menuList.splice(index, 1);
    },
    saveConfig: function saveConfig() {
      switch (this.typeName) {
        case "indexMenu":
          this.saveData("indexMenu", "/manghe/admin/page/layout/index/menu/save");
          break;

        case "indexBanner":
          this.saveData("indexBanner", "/manghe/admin/page/layout/index/banner/save");
          break;

        case "userMenu":
          this.saveData("userMenu", "/manghe/admin/page/layout/user/menu/save");
          break;

        case "indexNews":
          this.saveData("indexNews", "/manghe/admin/page/layout/index/news/save");
          break;

        case "userBanner":
          this.saveData("userBanner", "/manghe/admin/page/layout/user/banner/save");
          break;

        case "indexTabNav":
          this.saveData("indexTable", "/manghe/admin/page/layout/index/table/save");
      }
    },
    saveData: function saveData(param, url) {
      var _this4 = this;

      var tArr = this.menuList.filter(function (item, index, arr) {
        return item.status == true;
      });

      if (this.typeName == 'indexMenu' && tArr.length < 5) {
        this.$message.warning("设置数据不能小于5条");
      } else if (this.typeName == 'indexTabNav' && tArr.length < 2) {
        this.$message.warning("设置数据不能小于2条");
      } else {
        (0, _systemGroup.SaveDataApi)(_defineProperty({}, param, this.changeIndex(this.menuList)), url).then(function (res) {
          _this4.$message.success("保存成功");

          _this4.designList();
        });
      }
    },
    changeIndex: function changeIndex(array) {
      array.map(function (item, index) {
        item.sort = index;
      });
      return array;
    },
    // 移动
    handleDragStart: function handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd: function handleDragEnd(e, item) {
      this.dragging = null;
    },
    handleDragOver: function handleDragOver(e) {
      e.dataTransfer.dropEffect = "move";
    },
    handleDragEnter: function handleDragEnter(e, item) {
      e.dataTransfer.effectAllowed = "move";

      if (item === this.dragging) {
        return;
      }

      var newItems = _toConsumableArray(this.menuList);

      var src = newItems.indexOf(this.dragging);
      var dst = newItems.indexOf(item);
      newItems.splice.apply(newItems, [dst, 0].concat(_toConsumableArray(newItems.splice(src, 1))));
      this.menuList = newItems;
    },
    showCurrent: function showCurrent(name) {
      this.currentPage = name;
      this.$set(this, 'typeName', '');
      this.$set(this, 'tip', true);
      this.$set(this, 'cate', false);
    },
    showTip: function showTip() {
      this.$message.warning("暂不支持此操作");
    },
    cateNav: function cateNav() {
      this.currentPage = 'cate';
      this.$set(this, 'typeName', '');
      this.$set(this, 'cate', true);
      this.getConfig();
    },
    switchTab: function switchTab(index) {
      this.active = index;

      if (this.active == 0) {
        this.cateImg = require('@/assets/imgs/moren.png');
      } else if (this.active == 1) {
        this.cateImg = require('@/assets/imgs/youxuan.png');
      } else if (this.active == 2) {
        this.cateImg = require('@/assets/imgs/haowu.png');
      } else if (this.active == 3) {
        this.cateImg = require('@/assets/imgs/shengxian.png');
      }
    },
    save: function save() {
      var _this5 = this;

      var data = {
        category_page_config: this.active + 1,
        is_show_category: this.radio == '1' ? true : false
      };
      (0, _systemGroup.SaveDataApi)(data, '/manghe/admin/page/layout/category/config/save').then(function (res) {
        _this5.$message.success("保存成功");
      });
    },
    getConfig: function getConfig() {
      var _this6 = this;

      (0, _systemGroup.getDataApi)().then(function (res) {
        _this6.$set(_this6, 'active', res.categoryConfig - 1);

        _this6.$set(_this6, 'radio', res.isShowCategory == 'true' ? "1" : "0");

        _this6.switchTab(_this6.active);
      });
    }
  }
};
exports.default = _default;