"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

var _operation = _interopRequireDefault(require("./modules/operation"));

var _appSetting = _interopRequireDefault(require("./modules/appSetting"));

var _supplier = _interopRequireDefault(require("./modules/supplier"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

_vue.default.use(_vueRouter.default);
/* Layout */


/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 *
 */
var constantRoutes = [// 盲盒
// // 盲盒
// boxRouter,
// 商品
// // 订单
_supplier.default, // // 会员
// userRouter,
// // 分销
// distributionRouter,
// // 营销
// marketingRouter,
// // 财务
// financialRouter,
// // 内容
// contentRouter,
// // 设置
_operation.default, // // 应用
_appSetting.default, // // 维护
// maintainRouter,
// //移动端管理
// mobileRouter,
// // 数据
// statistic,
// 统计管理
// {
//   path: '/datas',
//   component: Layout,
//   redirect: '/datas',
//   name: 'Datas',
//   meta: {
//     title: '数据',
//     icon: 'clipboard'
//   },
//   children: [
//     {
//       path: 'transaction',
//       component: () => import('@/views/datas/transaction/index'),
//       name: 'Transaction',
//       meta: { title: '交易数据', icon: '' },
//       children: [
//         {
//           path: 'transactionorder',
//           component: () => import('@/views/datas/transaction/order/index'),
//           name: 'transactionOrder',
//           meta: { title: '订单统计', icon: '' }
//         },
//         {
//           path: 'transactiongoods',
//           component: () => import('@/views/datas/transaction/goods/index'),
//           name: 'transactionGoods',
//           meta: { title: '商品统计', icon: '' }
//         }
//       ]
//     }
//   ]
// },
{
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '主页',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/setting/uploadPicture',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/components/uploadPicture/index.vue'));
    });
  },
  name: 'uploadPicture'
}, // 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

exports.constantRoutes = constantRoutes;
var asyncRoutes = [];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    mode: 'history',
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;