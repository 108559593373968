"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.articleTypeFilter = articleTypeFilter;
exports.bargainColorFilter = bargainColorFilter;
exports.bargainStatusFilter = bargainStatusFilter;
exports.couponTypeFilter = couponTypeFilter;
exports.couponUserTypeFilter = couponUserTypeFilter;
exports.editStatusFilter = editStatusFilter;
exports.extractStatusFilter = extractStatusFilter;
exports.extractTypeFilter = extractTypeFilter;
exports.filterCategroyType = filterCategroyType;
exports.filterConfigCategory = filterConfigCategory;
exports.filterEmpty = filterEmpty;
exports.filterShowOrHide = filterShowOrHide;
exports.filterShowOrHideForFormConfig = filterShowOrHideForFormConfig;
exports.filterYesOrNo = filterYesOrNo;
exports.filterYesOrNoIs = filterYesOrNoIs;
exports.formatDate = formatDate;
exports.groupColorFilter = groupColorFilter;
exports.groupStatusFilter = groupStatusFilter;
exports.keywordStatusFilter = keywordStatusFilter;
exports.onePassTypeFilter = onePassTypeFilter;
exports.payStatusFilter = payStatusFilter;
exports.rechargeTypeFilter = rechargeTypeFilter;
exports.videoStatusFilter = videoStatusFilter;

var constants = _interopRequireWildcard(require("@/utils/constants.js"));

var _index = require("@/utils/index");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// 公共过滤器
function filterEmpty(val) {
  var _result = '-';

  if (!val) {
    return _result;
  }

  _result = val;
  return _result;
} // 时间过滤器


function formatDate(time) {
  if (time !== 0) {
    var date = new Date(time * 1000);
    return (0, _index.formatDates)(date, 'yyyy-MM-dd hh:mm');
  }
}

function filterYesOrNo(value) {
  return value ? '是' : '否';
}

function filterShowOrHide(value) {
  return value ? '显示' : '不显示';
}

function filterShowOrHideForFormConfig(value) {
  return value === '‘0’' ? '显示' : '不显示';
}

function filterYesOrNoIs(value) {
  return value ? '否' : '是';
}

function filterCategroyType(value) {
  return constants.categoryType.filter(function (item) {
    return value === item.value;
  })[0].name;
}

function filterConfigCategory(value) {
  return constants.configCategory.filter(function (item) {
    return value === item.value;
  })[0].label;
}
/**
 * @description 公众号回复类型
 */


function keywordStatusFilter(status) {
  var statusMap = {
    'text': '文字消息',
    'image': '图片消息',
    'news': '图文消息',
    'voice': '声音消息'
  };
  return statusMap[status];
}
/**
 * @description 优惠券类型
 */


function couponUserTypeFilter(status) {
  var statusMap = {
    1: '通用券',
    2: '商品券',
    3: '品类券'
  };
  return statusMap[status];
}
/**
 * @description 优惠券领取方式
 */


function couponTypeFilter(status) {
  var statusMap = {
    1: '手动领取',
    2: '新人券',
    3: '赠送券'
  };
  return statusMap[status];
}
/**
 * @description 文章分类
 */


function articleTypeFilter(status) {
  if (!status) {
    return '';
  }

  var arrayList = JSON.parse(localStorage.getItem('articleClass'));

  if (arrayList.filter(function (item) {
    return Number(status) === Number(item.id);
  }).length < 1) {
    return '';
  }

  return arrayList.filter(function (item) {
    return Number(status) === Number(item.id);
  })[0].name;
}
/**
 * @description 支付状态
 */


function payStatusFilter(status) {
  var statusMap = {
    false: '未支付',
    true: '已支付'
  };
  return statusMap[status];
}
/**
 * @description 提现方式
 */


function extractTypeFilter(status) {
  var statusMap = {
    'bank': '银行卡',
    'alipay': '支付宝',
    'weixin': '微信'
  };
  return statusMap[status];
}
/**
 * @description 充值类型
 */


function rechargeTypeFilter(status) {
  var statusMap = {
    'public': '微信公众号',
    'weixinh5': '微信H5支付',
    'routine': '小程序'
  };
  return statusMap[status];
}
/**
 * @description 财务审核状态
 */


function extractStatusFilter(status) {
  var statusMap = {
    '-1': '已拒绝',
    '0': '审核中',
    '1': '已提现'
  };
  return statusMap[status];
}
/**
 * @description 砍价状态
 */


function bargainStatusFilter(status) {
  var statusMap = {
    '1': '进行中',
    '2': '未完成',
    '3': '已成功'
  };
  return statusMap[status];
}
/**
 * @description 砍价状态
 */


function bargainColorFilter(status) {
  var statusMap = {
    '1': '',
    '2': 'danger',
    '3': 'success'
  };
  return statusMap[status];
}
/**
 * @description 拼团状态
 */


function groupStatusFilter(status) {
  var statusMap = {
    '1': '进行中',
    '2': '已成功',
    '3': '未完成'
  };
  return statusMap[status];
}
/**
 * @description 拼团状态
 */


function groupColorFilter(status) {
  var statusMap = {
    '1': '',
    '2': 'success',
    '3': 'danger'
  };
  return statusMap[status];
}
/**
 * @description 一号通tab值
 */


function onePassTypeFilter(status) {
  var statusMap = {
    'sms': '短信',
    'copy': '商品采集',
    'expr_query': '物流查询',
    'expr_dump': '电子面单打印'
  };
  return statusMap[status];
}
/**
 * @description 视频号商品草稿状态
 */


function editStatusFilter(status) {
  var statusMap = {
    1: '未审核',
    2: '审核中',
    3: '审核失败',
    4: '审核成功'
  };
  return statusMap[status];
}
/**
 * @description 视频号正式商品状态
 */


function videoStatusFilter(status) {
  var statusMap = {
    0: '初始值',
    5: '上架',
    11: '自主下架',
    13: '违规下架/风控系统下架'
  };
  return statusMap[status];
}