"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.attrCreatApi = attrCreatApi;
exports.attrDeleteApi = attrDeleteApi;
exports.attrEditApi = attrEditApi;
exports.attrInfoApi = attrInfoApi;
exports.categoryApi = categoryApi;
exports.copyConfigApi = copyConfigApi;
exports.copyProductApi = copyProductApi;
exports.importProductApi = importProductApi;
exports.offShellApi = offShellApi;
exports.productCreateApi = productCreateApi;
exports.productDeleteApi = productDeleteApi;
exports.productDetailApi = productDetailApi;
exports.productExcelApi = productExcelApi;
exports.productExportApi = productExportApi;
exports.productHeadersApi = productHeadersApi;
exports.productLstApi = productLstApi;
exports.productUpdateApi = productUpdateApi;
exports.putOnShellApi = putOnShellApi;
exports.replyCommentApi = replyCommentApi;
exports.replyCreatApi = replyCreatApi;
exports.replyDeleteApi = replyDeleteApi;
exports.replyEditApi = replyEditApi;
exports.replyInfoApi = replyInfoApi;
exports.replyListApi = replyListApi;
exports.restoreApi = restoreApi;
exports.templateListApi = templateListApi;
exports.updateSupplierStatus = updateSupplierStatus;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * 新增商品
 * @param pram
 */
function productCreateApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/store/supplier/product/save',
    method: 'POST',
    data: data
  });
}
/**
 * 编辑商品
 * @param pram
 */


function productUpdateApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/store/supplier/product/update',
    method: 'POST',
    data: data
  });
}
/**
 * 商品详情
 * @param pram
 */


function productDetailApi(id) {
  return (0, _request.default)({
    url: "/manghe/admin/store/product/info/".concat(id),
    method: 'GET'
  });
}
/**
 * 删除商品
 * @param pram
 */


function productDeleteApi(id, type) {
  return (0, _request.default)({
    url: "/manghe/admin/store/product/supplier/delete/".concat(id),
    method: 'get',
    params: {
      type: type
    }
  });
}
/**
 * 商品列表 表头数量
 */


function productHeadersApi() {
  return (0, _request.default)({
    url: '/manghe/admin/store/supplier/product/tabs/headers',
    method: 'GET'
  });
}
/**
 * 商品列表
 * @param pram
 */


function productLstApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/store/supplier/product/list',
    method: 'GET',
    params: params
  });
}
/**
 * 商品分类
 * @param pram
 */


function categoryApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/category/list/tree',
    method: 'GET',
    params: params
  });
}
/**
 * 商品上架
 * @param pram
 */


function putOnShellApi(id) {
  return (0, _request.default)({
    url: "/manghe/admin/store/supplier/product/putOnShell/".concat(id),
    method: 'GET'
  });
}
/**
 * 商品下架
 * @param pram
 */


function offShellApi(id) {
  return (0, _request.default)({
    url: "/manghe/admin/store/supplier/product/offShell/".concat(id),
    method: 'GET'
  });
}
/**
 * 商品规格 列表
 * @param pram
 */


function templateListApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/store/product/rule/list',
    method: 'GET',
    params: params
  });
}
/**
 * 商品规格 删除
 * @param pram
 */


function attrDeleteApi(id) {
  return (0, _request.default)({
    url: "/manghe/admin/store/supplier/product/rule/delete/".concat(id),
    method: 'get'
  });
}
/**
 * 商品规格 新增
 * @param pram
 */


function attrCreatApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/store/supplier/product/rule/save',
    method: 'POST',
    data: data
  });
}
/**
 * 商品规格 编辑
 * @param pram
 */


function attrEditApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/store/supplier/product/rule/update',
    method: 'POST',
    data: data
  });
}
/**
 * 商品规格 详情
 * @param pram
 */


function attrInfoApi(id) {
  return (0, _request.default)({
    url: "admin/store/product/rule/info/".concat(id),
    method: 'GET'
  });
}
/**
 * 商品评论 列表
 * @param pram
 */


function replyListApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/store/product/reply/list',
    method: 'GET',
    params: params
  });
}
/**
 * 商品评论 新增
 * @param pram
 */


function replyCreatApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/store/supplier/product/reply/save',
    method: 'POST',
    data: data
  });
}
/**
 * 商品评论 编辑
 * @param pram
 */


function replyEditApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/store/supplier/product/reply/update',
    method: 'POST',
    data: data
  });
}
/**
 * 商品评论 详情
 * @param pram
 */


function replyInfoApi(id) {
  return (0, _request.default)({
    url: "/manghe/admin/store/product/reply/info/".concat(id),
    method: 'GET'
  });
}
/**
 * 商品评论 删除
 * @param pram
 */


function replyDeleteApi(id) {
  return (0, _request.default)({
    url: "/manghe/admin/store/supplier/product/reply/delete/".concat(id),
    method: 'GET'
  });
}
/**
 * 商品评论 回复
 * @param pram
 */


function replyCommentApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/store/supplier/product/reply/comment",
    method: 'post',
    data: data
  });
}
/**
 * 商品评论 导出
 * @param pram
 */


function productExportApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/export/excel/product",
    method: 'get',
    params: params
  });
}
/**
 * 商品复制 99Api
 * @param pram
 */


function importProductApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/store/product/importProduct",
    method: 'post',
    params: params
  });
}
/**
 * 商品复制 一号通
 * @param pram
 */


function copyProductApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/store/product/copy/product",
    method: 'post',
    data: data
  });
}
/**
 * 修稿有货无货状态
 * @param pram
 */


function updateSupplierStatus(id) {
  return (0, _request.default)({
    url: "/manghe/admin/store/supplier/product/updateSupplierStatus/".concat(id),
    method: 'get'
  });
}
/**
 * 恢复
 * @param pram
 */


function restoreApi(id) {
  return (0, _request.default)({
    url: "/manghe/admin/store/product/restore/".concat(id),
    method: 'get'
  });
}
/**
 * 商品列表 导出
 * @param pram
 */


function productExcelApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/export/excel/product",
    method: 'get',
    params: params
  });
}
/**
 * 商品列表 获取复制商品配置
 * @param pram
 */


function copyConfigApi() {
  return (0, _request.default)({
    url: "/manghe/admin/store/product/copy/config",
    method: 'post'
  });
}