"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var articleApi = _interopRequireWildcard(require("@/api/article.js"));

var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));

var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: "list",
  props: {
    handle: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      templateRadio: '',
      imgList: [],
      constants: this.$constants,
      listPram: {
        keywords: null,
        cid: null,
        page: 1,
        limit: this.$constants.page.limit[0]
      },
      listData: {
        list: [],
        total: 0
      },
      editDialogConfig: {
        visible: false,
        data: {},
        isEdit: 0 // 0=add 1=edit

      },
      categoryTreeData: [],
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'child',
        expandTrigger: 'hover',
        checkStrictly: true,
        emitPath: false
      }
    };
  },
  mounted: function mounted() {
    this.handlerGetListData(this.listPram);
    this.handlerGetCategoryTreeData();
  },
  methods: {
    getTemplateRow: function getTemplateRow(row) {
      this.$emit('getArticle', row);
    },
    handerSearch: function handerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    handlerGetListData: function handlerGetListData(pram) {
      var _this = this;

      articleApi.ListArticle(pram).then(function (data) {
        _this.listData = data;

        _this.listData.list.map(function (item) {
          item.imageInput.map(function (i) {
            _this.imgList.push(i);
          });
        });
      });
    },
    handlerGetCategoryTreeData: function handlerGetCategoryTreeData() {
      var _this2 = this;

      var _pram = {
        type: this.constants.categoryType[2].value,
        status: 1
      };
      categoryApi.treeCategroy(_pram).then(function (data) {
        _this2.categoryTreeData = selfUtil.addTreeListLabelForCasCard(data);
      });
    },
    handlerHideDialog: function handlerHideDialog() {
      this.handlerGetListData(this.listPram);
      this.editDialogConfig.visible = false;
    },
    handlerDelete: function handlerDelete(rowData) {
      var _this3 = this;

      this.$confirm('确定删除当前数据', '提示').then(function (result) {
        articleApi.DelArticle(rowData).then(function (data) {
          _this3.$message.success('删除数据成功');

          _this3.handlerGetListData(_this3.listPram);
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData(this.listPram);
    }
  }
};
exports.default = _default;