import { render, staticRenderFns } from "./addPoint.vue?vue&type=template&id=fd59e378&"
import script from "./addPoint.vue?vue&type=script&lang=js&"
export * from "./addPoint.vue?vue&type=script&lang=js&"
import style0 from "./addPoint.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\repo\\supplierAdminWeb\\blindBoxAdminWeb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fd59e378')) {
      api.createRecord('fd59e378', component.options)
    } else {
      api.reload('fd59e378', component.options)
    }
    module.hot.accept("./addPoint.vue?vue&type=template&id=fd59e378&", function () {
      api.rerender('fd59e378', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/systemSetting/deliverGoods/takeGoods/deliveryAddress/addPoint.vue"
export default component.exports