"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _layout = _interopRequireDefault(require("@/layout"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var appSettingRouter = {
  path: '/appSetting',
  component: _layout.default,
  redirect: '/appSetting/publicAccount/wxMenus',
  name: 'appSetting',
  meta: {
    title: '应用',
    icon: 'clipboard'
  },
  children: [{
    path: 'publicAccount',
    name: 'publicAccount',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/appSetting/wxAccount'));
      });
    },
    meta: {
      title: '公众号',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'wxMenus',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/wxAccount/wxMenus'));
        });
      },
      name: 'wxMenus',
      meta: {
        title: '微信菜单',
        icon: ''
      }
    }, {
      path: 'wxReply',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/wxAccount/reply/index'));
        });
      },
      name: 'wxReply',
      meta: {
        title: '自动回复',
        icon: ''
      },
      children: [{
        path: 'follow',
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require('@/views/appSetting/wxAccount/reply/follow'));
          });
        },
        name: 'wxFollow',
        meta: {
          title: '微信关注回复',
          icon: ''
        }
      }, {
        path: 'keyword',
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require('@/views/appSetting/wxAccount/reply/keyword'));
          });
        },
        name: 'wxKeyword',
        meta: {
          title: '关键字回复',
          icon: ''
        }
      }, {
        path: 'replyIndex',
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require('@/views/appSetting/wxAccount/reply/follow'));
          });
        },
        name: 'wxReplyIndex',
        meta: {
          title: '无效关键词回复',
          icon: ''
        }
      }, {
        path: 'keyword/save/:id?',
        name: 'wechatKeywordAdd',
        meta: {
          title: '关键字添加',
          noCache: true,
          activeMenu: "/appSetting/publicAccount/wxReply/keyword"
        },
        hidden: true,
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require('@/views/appSetting/wxAccount/reply/follow'));
          });
        }
      }]
    }, {
      path: 'template/:type?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/wxAccount/wxTemplate'));
        });
      },
      name: 'wxTemplate',
      meta: {
        title: '微信模板消息',
        icon: ''
      }
    }]
  }, {
    path: 'publicRoutine',
    name: 'PublicRoutine',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/appSetting/routine'));
      });
    },
    meta: {
      title: '小程序',
      icon: 'clipboard',
      roles: ['admin'],
      hidden: true
    },
    children: [{
      path: 'template/:type?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/wxAccount/wxTemplate'));
        });
      },
      name: 'RoutineTemplate',
      meta: {
        title: '小程序订阅消息',
        icon: ''
      }
    }, {
      path: 'routineTemplate',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/routine/myTemplate'));
        });
      },
      name: 'RoutineTemplate',
      meta: {
        title: '我的模板',
        icon: ''
      }
    }, {
      path: 'publicRoutineTemplate',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/routine/publicTemplate/index.vue'));
        });
      },
      name: 'PublicRoutineTemplate',
      meta: {
        title: '公共模板',
        icon: ''
      }
    }, {
      path: 'creatPublicTemplate/:tid/:id/:myId?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/routine/publicTemplate/creatPublicTemplate.vue'));
        });
      },
      name: 'CreatPublicTemplate',
      meta: {
        title: '添加公共模板',
        icon: '',
        activeMenu: "/appSetting/publicRoutine/publicRoutineTemplate"
      },
      hidden: true
    }]
  }]
};
var _default = appSettingRouter;
exports.default = _default;