"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _systemGroup = require("@/api/systemGroup");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      active: 0,
      tabList: [{
        tit: '热情红',
        class: 'bg1'
      }, {
        tit: '家居橙',
        class: 'bg2'
      }, {
        tit: '生鲜绿',
        class: 'bg3'
      }, {
        tit: '海鲜蓝',
        class: 'bg4'
      }, {
        tit: '女神粉',
        class: 'bg5'
      }],
      themeData: [],
      imgList: [require('@/assets/theme/theme1.jpg')],
      theme1: [require('@/assets/theme/theme1.jpg')],
      theme2: [require('@/assets/theme/theme2.jpg')],
      theme3: [require('@/assets/theme/theme3.jpg')],
      theme4: [require('@/assets/theme/theme4.png')],
      theme5: [require('@/assets/theme/theme5.jpg')]
    };
  },
  mounted: function mounted() {
    this.getSet();
  },
  methods: {
    selected: function selected(index) {
      this.active = index;

      if (index == 0) {
        this.$set(this, 'imgList', this.theme1);
      } else if (index == 1) {
        this.$set(this, 'imgList', this.theme2);
      } else if (index == 2) {
        this.$set(this, 'imgList', this.theme3);
      } else if (index == 3) {
        this.$set(this, 'imgList', this.theme4);
      } else if (index == 4) {
        this.$set(this, 'imgList', this.theme5);
      }
    },
    getSet: function getSet() {
      var _this = this;

      (0, _systemGroup.getTheme)({
        key: 'change_color_config'
      }).then(function (res) {
        _this.active = res - 1;

        if (res == 1) {
          _this.$set(_this, 'imgList', _this.theme1);
        } else if (res == 2) {
          _this.$set(_this, 'imgList', _this.theme2);
        } else if (res == 3) {
          _this.$set(_this, 'imgList', _this.theme3);
        } else if (res == 4) {
          _this.$set(_this, 'imgList', _this.theme4);
        } else if (res == 5) {
          _this.$set(_this, 'imgList', _this.theme5);
        }
      });
    },
    saveTheme: function saveTheme() {
      var _this2 = this;

      var data = {
        key: 'change_color_config',
        value: this.active + 1
      };
      (0, _systemGroup.themeSave)(data).then(function (res) {
        _this2.$message.success('编辑成功');
      });
    }
  }
};
exports.default = _default;