"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var roleApi = _interopRequireWildcard(require("@/api/role.js"));

var categroyApi = _interopRequireWildcard(require("@/api/categoryApi.js"));

var _edit = _interopRequireDefault(require("./edit"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: "index"
  components: {
    edit: _edit.default
  },
  data: function data() {
    return {
      constants: this.$constants,
      listData: {
        list: []
      },
      listPram: {
        createTime: null,
        updateTime: null,
        level: null,
        page: 1,
        limit: this.$constants.page.limit[0],
        roleName: null,
        rules: null,
        status: null
      },
      menuList: [],
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建，1=编辑
        editData: {}
      }
    };
  },
  mounted: function mounted() {
    this.handleGetRoleList();
  },
  methods: {
    handlerOpenDel: function handlerOpenDel(rowData) {
      var _this = this;

      this.$confirm('确认删除当前数据').then(function () {
        roleApi.delRole(rowData).then(function (data) {
          _this.$message.success('删除数据成功');

          _this.handleGetRoleList();
        });
      });
    },
    handleGetRoleList: function handleGetRoleList() {
      var _this2 = this;

      roleApi.getRoleList(this.listPram).then(function (data) {
        _this2.listData = data;

        _this2.handlerGetMenuList();
      });
    },
    handlerOpenEdit: function handlerOpenEdit(isCreate, editDate) {
      isCreate === 1 ? this.editDialogConfig.editData = editDate : this.editDialogConfig.editData = {};
      this.editDialogConfig.isCreate = isCreate;
      this.editDialogConfig.visible = true;
    },
    handlerGetMenuList: function handlerGetMenuList() {
      var _this3 = this;

      // 获取菜单全部数据后做menu翻译使用
      categroyApi.listCategroy({
        page: 1,
        limit: 999,
        type: 5
      }).then(function (data) {
        _this3.menuList = data.list;

        _this3.listData.list.forEach(function (item) {
          var _muneText = [];
          var menuids = item.rules.split(',');
          menuids.map(function (muid) {
            _this3.menuList.filter(function (menu) {
              if (menu.id == muid) {
                _muneText.push(menu.name);
              }
            });
          });
          item.rulesView = _muneText.join(',');

          _this3.$set(item, 'rulesViews', item.rulesView);
        });
      });
    },
    hideEditDialog: function hideEditDialog() {
      this.editDialogConfig.visible = false;
      this.handleGetRoleList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handleGetRoleList(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handleGetRoleList(this.listPram);
    }
  }
};
exports.default = _default;