var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "leftModel" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentPage == "home",
                    expression: "currentPage == 'home'",
                  },
                ],
              },
              [
                _c("div", { staticClass: "model_header" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/imgs/index_logo.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "header_search" }, [
                    _c("span", { staticClass: "iconfont iconios-search" }),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "model_banner",
                    on: {
                      click: function ($event) {
                        return _vm.handleMessage("indexBanner")
                      },
                    },
                  },
                  [
                    _c(
                      "el-carousel",
                      {
                        attrs: {
                          "indicator-position": "none",
                          height: "139px",
                        },
                      },
                      _vm._l(_vm.dataList[1], function (item, index) {
                        return _c("el-carousel-item", { key: index }, [
                          _c("img", {
                            class: { select_ctive: _vm.shows == 1 },
                            staticStyle: {
                              width: "100%",
                              "border-radius": "4px",
                            },
                            attrs: { src: item.pic, alt: "" },
                          }),
                        ])
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "model_news",
                    class: { select_ctive: _vm.shows == 2 },
                    on: {
                      click: function ($event) {
                        return _vm.handleMessage("indexNews")
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "64px", height: "17px" },
                      attrs: {
                        src: require("@/assets/imgs/new_header1.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "#ccc" } }, [
                      _vm._v("|"),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("CRMEB电商系统Java版正式发布！")]),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-arrow-right" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "model_nav ",
                    class: { select_ctive: _vm.shows == 3 },
                    on: {
                      click: function ($event) {
                        return _vm.handleMessage("indexMenu")
                      },
                    },
                  },
                  _vm._l(_vm.dataList[0], function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "model_nav_item" },
                      [
                        _c("div", [
                          _c("img", { attrs: { src: item.pic, alt: "" } }),
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(item.name))]),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab_nav_bd",
                    class: { select_ctive: _vm.shows == 4 },
                    on: {
                      click: function ($event) {
                        return _vm.handleMessage("indexTabNav")
                      },
                    },
                  },
                  _vm._l(_vm.indexTab, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "nav_bd_item",
                        class: { label_active: _vm.tabActive === index },
                        on: {
                          click: function ($event) {
                            return _vm.switchNav(index)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "item_txt" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "item_label" }, [
                          _vm._v(_vm._s(item.info)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "moni_goods" }, [
                  _vm._v("模拟商品区域"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentPage == "cate",
                    expression: "currentPage == 'cate'",
                  },
                ],
              },
              [
                _c("img", {
                  staticStyle: { width: "375px" },
                  attrs: { src: _vm.cateImg, alt: "" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentPage == "user",
                    expression: "currentPage == 'user'",
                  },
                ],
              },
              [
                _c("div", { staticClass: "user_head" }, [
                  _c(
                    "div",
                    {
                      staticClass: "user_bg",
                      style: { backgroundImage: "url(" + _vm.urlbg + ")" },
                    },
                    [
                      _c("div", { staticClass: "user_card" }, [
                        _c("div", { staticClass: "user_info" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/imgs/default_avatar.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "info" }, [
                            _c("p", { staticClass: "nick_name" }, [
                              _vm._v("用户信息"),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "phone" }, [
                              _vm._v("123456"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "num_wrapper" }, [
                          _c("div", { staticClass: "num_wrap_item" }, [
                            _c("p", { staticClass: "num_item_bold" }, [
                              _vm._v("0"),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "num_title" }, [
                              _vm._v("余额"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "num_wrap_item" }, [
                            _c("p", { staticClass: "num_item_bold" }, [
                              _vm._v("0"),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "num_title" }, [
                              _vm._v("积分"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "num_wrap_item" }, [
                            _c("p", { staticClass: "num_item_bold" }, [
                              _vm._v("0"),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "num_title" }, [
                              _vm._v("优惠券"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "num_wrap_item" }, [
                            _c("p", { staticClass: "num_item_bold" }, [
                              _vm._v("0"),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "num_title" }, [
                              _vm._v("收藏"),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "order_wrap" }, [
                        _c("div", { staticClass: "order_wrap_tit" }, [
                          _c("span", { staticClass: "weight_600" }, [
                            _vm._v("订单中心"),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("span", { staticClass: "font_sm" }, [
                              _vm._v("查看全部"),
                            ]),
                            _vm._v(" "),
                            _c("i", { staticClass: "el-icon-arrow-right" }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "order_wrap_list" }, [
                          _c("div", { staticClass: "order_list_item" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/fukuan.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", [_vm._v("待付款")]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "order_list_item" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/fahuo.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", [_vm._v("待发货")]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "order_list_item" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/shouhuo.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", [_vm._v("待收货")]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "order_list_item" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/pingjia.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", [_vm._v("待评价")]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "order_list_item" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/tuikuan.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", [_vm._v("售后/退款")]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "slider_img",
                          class: { select_ctive: _vm.shows == 5 },
                          on: {
                            click: function ($event) {
                              return _vm.handleMessage("userBanner")
                            },
                          },
                        },
                        [
                          _c(
                            "el-carousel",
                            { attrs: { height: "69px", autoplay: true } },
                            _vm._l(_vm.dataList[4], function (item, index) {
                              return _c("el-carousel-item", { key: index }, [
                                _c("img", {
                                  staticStyle: {
                                    height: "69px",
                                    display: "block",
                                    margin: "auto",
                                  },
                                  attrs: { src: item.pic, alt: "" },
                                }),
                              ])
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "user_mens",
                          class: { select_ctive: _vm.shows == 6 },
                          on: {
                            click: function ($event) {
                              return _vm.handleMessage("userMenu")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "menu_title" }, [
                            _vm._v("我的服务"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "list_box" },
                            _vm._l(_vm.dataList[2], function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "list_box_item" },
                                [
                                  _c("img", {
                                    attrs: { src: item.pic, alt: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("p", [_vm._v(_vm._s(item.name))]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "fixed_tab" }, [
              _c(
                "div",
                {
                  staticClass: "bottom_tab_item",
                  on: {
                    click: function ($event) {
                      return _vm.showCurrent("home")
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/imgs/tabBar/index.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {}, [_vm._v("首页")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bottom_tab_item",
                  on: {
                    click: function ($event) {
                      return _vm.cateNav()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/imgs/tabBar/cate.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {}, [_vm._v("分类")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bottom_tab_item shop_cart",
                  on: {
                    click: function ($event) {
                      return _vm.showTip()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/imgs/tabBar/cart.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v("购物车")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bottom_tab_item",
                  on: {
                    click: function ($event) {
                      return _vm.showCurrent("user")
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/imgs/tabBar/user.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {}, [_vm._v("我的")]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex_between" }, [
            _vm.typeName
              ? _c("div", { staticClass: "right-box" }, [
                  _c("div", { staticClass: "title-bar" }, [_vm._v("模块配置")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mobile-config" },
                    [
                      _vm._l(_vm.menuList, function (item, index) {
                        return _c("div", { key: index, staticClass: "item" }, [
                          _c(
                            "div",
                            {
                              staticClass: "move-icon",
                              attrs: { draggable: "true" },
                              on: {
                                dragstart: function ($event) {
                                  return _vm.handleDragStart($event, item)
                                },
                                dragover: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleDragOver($event, item)
                                },
                                dragenter: function ($event) {
                                  return _vm.handleDragEnter($event, item)
                                },
                                dragend: function ($event) {
                                  return _vm.handleDragEnd($event, item)
                                },
                              },
                            },
                            [_c("span", { staticClass: "iconfont icondrag2" })]
                          ),
                          _vm._v(" "),
                          _vm.typeName !== "indexNews"
                            ? _c("div", { staticClass: "picBox" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "img-box",
                                    on: {
                                      click: function ($event) {
                                        return _vm.modalPicTap(
                                          "1",
                                          "duo",
                                          index,
                                          true
                                        )
                                      },
                                    },
                                  },
                                  [
                                    item.pic
                                      ? _c("img", {
                                          attrs: { src: item.pic, alt: "" },
                                        })
                                      : _c("div", { staticClass: "upLoad" }, [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-camera cameraIconfont",
                                          }),
                                        ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          index > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "delect-btn",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.bindDelete(item, index)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-circle-close",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "info" }, [
                            _vm.typeName !== "userBanner"
                              ? _c("div", { staticClass: "info-item" }, [
                                  _c("span", [_vm._v("标题")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "input-box" },
                                    [
                                      _vm.typeName !== "indexNews"
                                        ? _c("el-input", {
                                            attrs: {
                                              placeholder: "请填写" + item.name,
                                              maxlength: "4",
                                            },
                                            model: {
                                              value: item.name,
                                              callback: function ($$v) {
                                                _vm.$set(item, "name", $$v)
                                              },
                                              expression: "item.name",
                                            },
                                          })
                                        : _c("el-input", {
                                            attrs: {
                                              placeholder: "请填写" + item.info,
                                            },
                                            model: {
                                              value: item.info,
                                              callback: function ($$v) {
                                                _vm.$set(item, "info", $$v)
                                              },
                                              expression: "item.info",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.addUrlStatus && _vm.typeName !== "indexTabNav"
                              ? _c("div", { staticClass: "info-item" }, [
                                  _c("span", [_vm._v("链接")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "input-box" },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "请填写链接" },
                                        model: {
                                          value: item.url,
                                          callback: function ($$v) {
                                            _vm.$set(item, "url", $$v)
                                          },
                                          expression: "item.url",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.typeName == "indexTabNav"
                              ? _c("div", { staticClass: "info-item" }, [
                                  _c("span", [_vm._v("简介")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "input-box" },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "请填写简介" },
                                        model: {
                                          value: item.info,
                                          callback: function ($$v) {
                                            _vm.$set(item, "info", $$v)
                                          },
                                          expression: "item.info",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "info-item" }, [
                              _c("span", [_vm._v("状态")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "input-box" },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": true,
                                      "inactive-value": false,
                                      "active-text": "显示",
                                      "inactive-text": "隐藏",
                                    },
                                    model: {
                                      value: item.status,
                                      callback: function ($$v) {
                                        _vm.$set(item, "status", $$v)
                                      },
                                      expression: "item.status",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ])
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "add-btn mb20 mt20" },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "100%",
                                height: "40px",
                                "border-color": "#1890ff",
                                color: "#1890ff",
                              },
                              attrs: { ghost: "" },
                              on: { click: _vm.addBox },
                            },
                            [_vm._v("添加数据\n              ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "100%",
                                margin: "0 auto",
                                height: "40px",
                              },
                              attrs: { type: "primary" },
                              on: { click: _vm.saveConfig },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.typeName
              ? _c(
                  "div",
                  { staticClass: "right-box" },
                  _vm._l(_vm.list, function (item, index) {
                    return _c("div", { key: index, staticClass: "link-item" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [
                        _c("span", [_vm._v("地址：")]),
                        _vm._v(_vm._s(item.url)),
                      ]),
                      _vm._v(" "),
                      item.parameter
                        ? _c("div", { staticClass: "txt" }, [
                            _c("p", [_c("span", [_vm._v("参数：")])]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.parameter))]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "tips" }, [
                        _vm._v(
                          "\n              例如：" +
                            _vm._s(item.example) +
                            "\n              "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "copy copy-data",
                            attrs: { "data-clipboard-text": item.example },
                          },
                          [_vm._v("复制")]
                        ),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.typeName && _vm.tip == true && _vm.cate == false
              ? _c("div", { staticClass: "tip" }, [
                  _vm._v("请选择左侧可操作可编辑区域"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.typeName == "" && _vm.currentPage == "cate" && _vm.cate == true
              ? _c(
                  "div",
                  {},
                  [
                    _c(
                      "el-row",
                      _vm._l(_vm.cateArr, function (item, index) {
                        return _c(
                          "el-col",
                          {
                            key: index,
                            attrs: { span: 5, offset: index > 0 ? 1 : 0 },
                          },
                          [
                            _c(
                              "el-card",
                              {
                                staticClass: "card_cate",
                                class: index == _vm.active ? "on" : "",
                                attrs: { "body-style": { padding: "0px" } },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "card_bt flex justify-between align-center",
                                  },
                                  [_c("span", [_vm._v(_vm._s(item.tit))])]
                                ),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "image",
                                  attrs: { src: item.img },
                                  on: {
                                    click: function ($event) {
                                      return _vm.switchTab(index)
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "20px",
                          "font-size": "16px",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _vm._v(
                              "\n                是否显示左侧一级菜单：\n                "
                            ),
                            _c(
                              "el-radio-group",
                              {
                                attrs: {
                                  disabled: _vm.active == 0 || _vm.active == 1,
                                },
                                model: {
                                  value: _vm.radio,
                                  callback: function ($$v) {
                                    _vm.radio = $$v
                                  },
                                  expression: "radio",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  { attrs: { label: "1", border: "" } },
                                  [_vm._v("显示")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio",
                                  { attrs: { label: "0", border: "" } },
                                  [_vm._v("不显示")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "20px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.save()
                                  },
                                },
                              },
                              [_vm._v("保存")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }