"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRoleById = getRoleById;
exports.menuListApi = menuListApi;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getRoleById(pram) {
  var data = {
    ids: pram.roles
  };
  return (0, _request.default)({
    url: '/manghe/admin/system/role/info',
    method: 'GET',
    params: data
  });
}
/**
 * 菜单
 * @param pram
 */


function menuListApi() {
  return (0, _request.default)({
    url: '/manghe/admin/system/role/menu',
    method: 'GET'
  });
}