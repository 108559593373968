"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.attachmentMoveApi = attachmentMoveApi;
exports.fileDeleteApi = fileDeleteApi;
exports.fileFileApi = fileFileApi;
exports.fileImageApi = fileImageApi;
exports.fileListApi = fileListApi;
exports.systemConfigCheck = systemConfigCheck;
exports.systemConfigInfo = systemConfigInfo;
exports.systemConfigSave = systemConfigSave;
exports.wechatUploadApi = wechatUploadApi;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function systemConfigCheck(pram) {
  var data = {
    name: pram.name
  };
  return (0, _request.default)({
    url: '/manghe/admin/system/config/check',
    method: 'GET',
    params: data
  });
}

function systemConfigInfo(pram) {
  var data = {
    formId: pram.id
  };
  return (0, _request.default)({
    url: '/manghe/admin/system/config/info',
    method: 'GET',
    params: data
  });
}

function systemConfigSave(pram) {
  return (0, _request.default)({
    url: '/manghe/admin/system/config/save/form',
    method: 'POST',
    data: pram
  });
}
/**
 * 文件上传
 * @param data
 */


function fileFileApi(data, params) {
  return (0, _request.default)({
    url: '/manghe/admin/upload/file',
    method: 'POST',
    params: params,
    data: data
  });
}
/**
 * 图片上传
 * @param data
 */


function fileImageApi(data, params) {
  return (0, _request.default)({
    url: '/manghe/admin/upload/image',
    method: 'POST',
    params: params,
    data: data
  });
}
/**
 * 图片列表
 * @param data
 */


function fileListApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/system/attachment/list',
    method: 'get',
    params: params
  });
}
/**
 * 图片列表 删除图片
 * @param data
 */


function fileDeleteApi(id) {
  return (0, _request.default)({
    url: "/manghe/admin/system/attachment/delete/".concat(id),
    method: 'get'
  });
}
/**
 * 图片列表 移動分類
 * @param data
 */


function attachmentMoveApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/system/attachment/move",
    method: 'post',
    data: data
  });
}
/**
 * 微信上传图片
 * @param data
 */


function wechatUploadApi(data, params) {
  return (0, _request.default)({
    url: "/manghe/admin/wechat/media/upload",
    method: 'post',
    data: data,
    params: params
  });
}