"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.wxCategoryFilter = wxCategoryFilter;
exports.wxTypeFilter = wxTypeFilter;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//小程序 微信过滤器

/**
 * @description 小程序所属类目
 */
function wxCategoryFilter(status) {
  if (!status) {
    return '';
  }

  if (!_jsCookie.default.get('WxCategory')) {
    return;
  }

  var arrayList = JSON.parse(_jsCookie.default.get('WxCategory'));

  if (arrayList.filter(function (item) {
    return Number(status) === Number(item.id);
  }).length < 1) {
    return '';
  }

  return arrayList.filter(function (item) {
    return Number(status) === Number(item.id);
  })[0].name;
}
/**
 * @description 小程序模板类型
 */


function wxTypeFilter(status) {
  var statusMap = {
    2: '一次性订阅',
    3: '长期订阅'
  };
  return statusMap[status];
}