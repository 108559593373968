"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _order = require("@/api/order");

var _index = _interopRequireDefault(require("@/components/cards/index"));

var _ZBParser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/ZBParser"));

var _orderDetail = _interopRequireDefault(require("./orderDetail"));

var _orderSend = _interopRequireDefault(require("./orderSend"));

var _uploadFile = _interopRequireDefault(require("@/components/Upload/uploadFile.vue"));

var _orderSupplier = _interopRequireDefault(require("./orderSupplier"));

var _orderVideoSend = _interopRequireDefault(require("./orderVideoSend"));

var _utils = require("@/utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'orderlistDetails',
  components: {
    cardsData: _index.default,
    zbParser: _ZBParser.default,
    detailsFrom: _orderDetail.default,
    orderSend: _orderSend.default,
    orderSupplier: _orderSupplier.default,
    orderVideoSend: _orderVideoSend.default,
    Upload: _uploadFile.default
  },
  data: function data() {
    return {
      options: [{
        value: "box",
        label: '盲盒提取'
      }, {
        value: "buy",
        label: '商城购买'
      }, {
        value: "gift",
        label: '赠送提取'
      }],
      sourceOptions: [],
      device_typeOptions: [],
      RefuseVisible: false,
      RefuseData: {},
      orderId: '',
      refundVisible: false,
      uploadExcelVisible: false,
      refundData: {},
      dialogVisibleJI: false,
      tableDataLog: {
        data: [],
        total: 0
      },
      tableFromLog: {
        page: 1,
        limit: 10,
        orderNo: 0
      },
      LogLoading: false,
      isCreate: 1,
      editData: null,
      dialogVisible: false,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        status: 'all',
        dateLimit: '',
        orderNo: '',
        page: 1,
        limit: 20,
        type: ''
      },
      orderChartType: {},
      timeVal: [],
      fromList: this.$constants.fromList,
      selectionList: [],
      ids: '',
      orderids: '',
      cardLists: [],
      isWriteOff: (0, _utils.isWriteOff)(),
      proType: 0,
      active: false
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getOrderStatusNum();
    this.getOrderListData();
  },
  methods: {
    beforeUploadExcel: function beforeUploadExcel() {
      console.info("beforeUploadExcel：-------");
      this.uploadExcelVisible = false;
      return true;
    },
    afterUploadExcel: function afterUploadExcel(e) {
      console.info("afterUploadExcel：-------");
      console.info(e);
      return true;
    },
    resetFormRefundhandler: function resetFormRefundhandler() {
      this.refundVisible = false;
    },
    refreshNumsAndList: function refreshNumsAndList() {
      this.getList();
      this.getOrderStatusNum();
    },
    resetFormRefusehand: function resetFormRefusehand() {
      this.RefuseVisible = false;
    },
    resetForm: function resetForm(formValue) {
      this.dialogVisible = false;
    },
    // 核销订单
    onWriteOff: function onWriteOff(row) {
      var _this = this;

      this.$modalSure('核销订单吗').then(function () {
        (0, _order.writeUpdateApi)(row.verifyCode).then(function () {
          _this.$message.success('核销成功');

          _this.tableFrom.page = 1;

          _this.getList();
        });
      });
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    uploadExcel: function uploadExcel() {
      this.uploadExcelVisible = true;
    },
    //  设置供应商
    setSupplier: function setSupplier() {
      if (this.selectionList.length === 0) return this.$message.warning('请先选择订单');

      if (this.tableFrom.status != 'notShipped') {
        return this.$message.warning('必须是未发货订单');
      }

      this.$refs.supplier.orderIds = this.ids;
      this.$refs.supplier.modals = true;
      this.$refs.supplier.supplierOptionList();
    },
    //  取消设置供应商
    cancelSupplier: function cancelSupplier() {
      var _this2 = this;

      if (this.selectionList.length === 0) return this.$message.warning('请先选择订单');

      if (this.tableFrom.status != 'waitSupplier') {
        return this.$message.warning('必须是等待未供应商发货订单');
      }

      (0, _order.cancelSupplierApi)({
        ids: this.ids,
        supplier: "0"
      }).then(function (data) {
        _this2.$message.success('操作成功');

        _this2.refreshNumsAndList();
      });
    },
    // 拒绝退款
    RefusehandleClose: function RefusehandleClose() {
      this.RefuseVisible = false;
    },
    onOrderRefuse: function onOrderRefuse(row) {
      this.orderids = row.orderId;
      this.RefuseData = {
        orderId: row.orderId,
        reason: ''
      };
      this.RefuseVisible = true;
    },
    RefusehandlerSubmit: function RefusehandlerSubmit(formValue) {
      var _this3 = this;

      (0, _order.orderRefuseApi)({
        orderNo: this.orderids,
        reason: formValue.reason
      }).then(function (data) {
        _this3.$message.success('操作成功');

        _this3.RefuseVisible = false;

        _this3.getList();
      });
    },
    // 立即退款
    refundhandleClose: function refundhandleClose() {
      this.refundVisible = false;
    },
    onOrderRefund: function onOrderRefund(row) {
      this.refundData = {
        orderId: row.orderId,
        amount: row.payPrice,
        type: ''
      };
      this.orderids = row.orderId;
      this.refundVisible = true;
    },
    refundhandlerSubmit: function refundhandlerSubmit(formValue) {
      var _this4 = this;

      (0, _order.orderRefundApi)({
        amount: formValue.amount,
        orderNo: this.orderids
      }).then(function (data) {
        _this4.$message.success('操作成功');

        _this4.refundVisible = false;

        _this4.getList();
      });
    },
    // 发送
    sendOrder: function sendOrder(row) {
      if (row.type === 0) {
        this.$refs.send.modals = true;
        this.$refs.send.getList();
        this.$refs.send.sheetInfo();
      } else {
        this.$refs.videoSend.modals = true;
        if (!JSON.parse(sessionStorage.getItem('videoExpress'))) this.$refs.videoSend.companyGetList();
      }

      this.orderId = row.orderId;
    },
    // 订单删除
    handleDelete: function handleDelete(row, idx) {
      var _this5 = this;

      if (row.isDel) {
        this.$modalSure().then(function () {
          (0, _order.orderDeleteApi)({
            orderNo: row.orderId
          }).then(function () {
            _this5.$message.success('删除成功');

            _this5.tableData.data.splice(idx, 1);
          });
        });
      } else {
        this.$confirm('您选择的的订单存在用户未删除的订单，无法删除用户未删除的订单！', '提示', {
          confirmButtonText: '确定',
          type: 'error'
        });
      }
    },
    // 详情
    onOrderDetails: function onOrderDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.getDetail(id);
      this.$refs.orderDetail.dialogVisible = true;
    },
    // 订单记录
    onOrderLog: function onOrderLog(id) {
      var _this6 = this;

      this.dialogVisibleJI = true;
      this.LogLoading = true;
      this.tableFromLog.orderNo = id;
      (0, _order.orderLogApi)(this.tableFromLog).then(function (res) {
        _this6.tableDataLog.data = res.list;
        _this6.tableDataLog.total = res.total;
        _this6.LogLoading = false;
      }).catch(function () {
        _this6.LogLoading = false;
      });
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.onOrderLog();
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.onOrderLog();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 备注
    onOrderMark: function onOrderMark(row) {
      var _this7 = this;

      this.$prompt('订单备注', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '请输入订单备注',
        inputType: 'textarea',
        inputValue: row.remark,
        inputPlaceholder: '请输入订单备注',
        inputValidator: function inputValidator(value) {
          if (!value) return '输入不能为空';
        }
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _order.orderMarkApi)({
          mark: value,
          orderNo: row.orderId
        }).then(function () {
          _this7.$message.success('操作成功');

          _this7.getList();
        });
      }).catch(function () {
        _this7.$message.info('取消输入');
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectionList = val;
      var data = [];
      this.selectionList.map(function (item) {
        data.push(item.orderId);
      });
      this.ids = data.join(',');
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
      this.getOrderListData();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
      this.getOrderListData();
    },
    // 编辑
    edit: function edit(row) {
      this.orderId = row.orderId;
      this.editData = {
        orderId: row.orderId,
        totalPrice: row.totalPrice,
        totalPostage: row.totalPostage,
        payPrice: row.payPrice,
        payPostage: row.payPostage,
        gainIntegral: row.gainIntegral
      };
      console.log('this.editData:', this.editData);
      this.dialogVisible = true;
    },
    handlerSubmit: function handlerSubmit(formValue) {
      var _this8 = this;

      var data = {
        orderNo: formValue.orderId,
        payPrice: formValue.payPrice
      };
      (0, _order.updatePriceApi)(data).then(function (data) {
        _this8.$message.success('编辑数据成功');

        _this8.dialogVisible = false;

        _this8.getList();
      });
    },
    downloadExcel: function downloadExcel() {
      var _this9 = this;

      this.listLoading = true;
      (0, _order.downloadExcelApi)(this.tableFrom).then(function (res) {
        _this9.listLoading = false;
        window.open(res.url, "_blank");
      }).catch(function () {
        _this9.listLoading = false;
      });
    },
    // 列表
    getList: function getList() {
      var _this10 = this;

      this.listLoading = true;
      (0, _order.orderListApi)(this.tableFrom).then(function (res) {
        _this10.tableData.data = res.list || [];
        _this10.tableData.total = res.total;
        _this10.listLoading = false;
      }).catch(function () {
        _this10.listLoading = false;
      });
    },
    // sourceOption列表
    sourceOptionList: function sourceOptionList() {
      var _this11 = this;

      var param = {
        "configKey": "box_source_option"
      };
      boxConfigOptionListApi(param).then(function (res) {
        _this11.sourceOptions = res;
      });
    },
    // deviceTypeOption列表
    device_typeOptionList: function device_typeOptionList() {
      var _this12 = this;

      var param = {
        "configKey": "box_device_type_option"
      };
      boxConfigOptionListApi(param).then(function (res) {
        _this12.device_typeOptions = res;
      });
    },
    // 数据统计
    getOrderListData: function getOrderListData() {},
    // 获取各状态数量
    getOrderStatusNum: function getOrderStatusNum() {
      var _this13 = this;

      (0, _order.orderStatusNumApi)({
        dateLimit: this.tableFrom.dateLimit
      }).then(function (res) {
        _this13.orderChartType = res;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};
exports.default = _default;