"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _wxApi = require("@/api/wxApi");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "index",
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 20,
        title: '',
        type: '',
        categoryId: ''
      },
      categoryList: []
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getCategoryList();
  },
  methods: {
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _wxApi.publicTempListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list || [];
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 所属类目
    getCategoryList: function getCategoryList() {
      var _this2 = this;

      (0, _wxApi.categoryApi)().then(function (res) {
        _this2.categoryList = res;

        _jsCookie.default.set('WxCategory', res);
      });
    }
  }
};
exports.default = _default;