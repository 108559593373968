"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bargainDeleteApi = bargainDeleteApi;
exports.bargainInfoApi = bargainInfoApi;
exports.bargainListApi = bargainListApi;
exports.bargainListListApi = bargainListListApi;
exports.bargainOrderPinkApi = bargainOrderPinkApi;
exports.bargainSaveApi = bargainSaveApi;
exports.bargainStatusApi = bargainStatusApi;
exports.bargainUpdateApi = bargainUpdateApi;
exports.catListApi = catListApi;
exports.combinationDeleteApi = combinationDeleteApi;
exports.combinationInfoApi = combinationInfoApi;
exports.combinationListApi = combinationListApi;
exports.combinationSaveApi = combinationSaveApi;
exports.combinationStatusApi = combinationStatusApi;
exports.combinationUpdateApi = combinationUpdateApi;
exports.combineListApi = combineListApi;
exports.combineOrderPinkApi = combineOrderPinkApi;
exports.combineStatisticsApi = combineStatisticsApi;
exports.couponDeleteApi = couponDeleteApi;
exports.couponInfoApi = couponInfoApi;
exports.couponIssueStatusApi = couponIssueStatusApi;
exports.couponSaveApi = couponSaveApi;
exports.couponUserApi = couponUserApi;
exports.couponUserListApi = couponUserListApi;
exports.draftInfoApi = draftInfoApi;
exports.draftListApi = draftListApi;
exports.draftUpdateApi = draftUpdateApi;
exports.exportBargainApi = exportBargainApi;
exports.exportcombiantionApi = exportcombiantionApi;
exports.integralListApi = integralListApi;
exports.marketingListApi = marketingListApi;
exports.marketingSendApi = marketingSendApi;
exports.seckillConfigStatusApi = seckillConfigStatusApi;
exports.seckillDeleteApi = seckillDeleteApi;
exports.seckillInfoApi = seckillInfoApi;
exports.seckillListApi = seckillListApi;
exports.seckillSaveApi = seckillSaveApi;
exports.seckillStoreDeleteApi = seckillStoreDeleteApi;
exports.seckillStoreInfoApi = seckillStoreInfoApi;
exports.seckillStoreListApi = seckillStoreListApi;
exports.seckillStoreSaveApi = seckillStoreSaveApi;
exports.seckillStoreStatusApi = seckillStoreStatusApi;
exports.seckillStoreUpdateApi = seckillStoreUpdateApi;
exports.seckillUpdateApi = seckillUpdateApi;
exports.videoAddApi = videoAddApi;
exports.videoDelApi = videoDelApi;
exports.videoDownApi = videoDownApi;
exports.videoProductListApi = videoProductListApi;
exports.videoUpApi = videoUpApi;
exports.videoUpdateApi = videoUpdateApi;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * 优惠券 列表
 * @param pram
 */
function marketingListApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/marketing/coupon/list',
    method: 'get',
    params: params
  });
}
/**
 * 用户管理 优惠券列表
 * @param pram
 */


function marketingSendApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/marketing/coupon/send/list',
    method: 'get',
    params: params
  });
}
/**
 * 优惠券 详情
 * @param pram
 */


function couponInfoApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/marketing/coupon/info',
    method: 'post',
    params: params
  });
}
/**
 * 优惠券 发送
 * @param pram
 */


function couponUserApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/marketing/coupon/user/receive',
    method: 'post',
    params: params
  });
}
/**
 * 优惠券 发送
 * @param pram
 */


function couponSaveApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/marketing/coupon/save',
    method: 'post',
    data: data
  });
}
/**
 * 优惠券 修改状态
 * @param pram
 */


function couponIssueStatusApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/marketing/coupon/update/status',
    method: 'post',
    params: params
  });
}
/**
 * 优惠券 删除
 * @param pram
 */


function couponDeleteApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/marketing/coupon/delete',
    method: 'post',
    params: params
  });
}
/**
 * 会员领取记录 列表
 * @param pram
 */


function couponUserListApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/marketing/coupon/user/list',
    method: 'get',
    params: params
  });
}
/**
 * 积分日志 列表
 * @param pram
 */


function integralListApi(params, data) {
  return (0, _request.default)({
    url: '/manghe/admin/user/integral/list',
    method: 'post',
    params: params,
    data: data
  });
}
/**
 * 秒杀配置 列表
 * @param pram
 */


function seckillListApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/store/seckill/manger/list',
    method: 'get',
    params: params
  });
}
/**
 * 秒杀配置 详情
 * @param pram
 */


function seckillInfoApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/store/seckill/manger/info',
    method: 'get',
    params: params
  });
}
/**
 * 秒杀配置 新增
 * @param pram
 */


function seckillSaveApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/store/seckill/manger/save',
    method: 'post',
    data: data
  });
}
/**
 * 秒杀配置 修改
 * @param pram
 */


function seckillUpdateApi(params, data) {
  return (0, _request.default)({
    url: '/manghe/admin/store/seckill/manger/update',
    method: 'post',
    params: params,
    data: data
  });
}
/**
 * 秒杀配置 删除
 * @param pram
 */


function seckillDeleteApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/store/seckill/manger/delete',
    method: 'get',
    params: params
  });
}
/**
 * 秒杀商品 列表
 * @param pram
 */


function seckillStoreListApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/store/seckill/list',
    method: 'get',
    params: params
  });
}
/**
 * 秒杀商品 详情
 * @param pram
 */


function seckillStoreInfoApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/store/seckill/info',
    method: 'get',
    params: params
  });
}
/**
 * 秒杀商品 新增
 * @param pram
 */


function seckillStoreSaveApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/store/seckill/save',
    method: 'post',
    data: data
  });
}
/**
 * 秒杀商品 修改
 * @param pram
 */


function seckillStoreUpdateApi(params, data) {
  return (0, _request.default)({
    url: '/manghe/admin/store/seckill/update',
    method: 'post',
    params: params,
    data: data
  });
}
/**
 * 秒杀商品 删除
 * @param pram
 */


function seckillStoreDeleteApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/store/seckill/delete',
    method: 'get',
    params: params
  });
}
/**
 * 秒杀商品 修改状态
 */


function seckillStoreStatusApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/store/seckill/update/status',
    method: 'post',
    params: params
  });
}
/**
 * 秒杀配置 修改状态
 */


function seckillConfigStatusApi(id, params) {
  return (0, _request.default)({
    url: "/manghe/admin/store/seckill/manger/update/status/".concat(id),
    method: 'post',
    params: params
  });
}
/**
 * 砍价商品 列表
 */


function bargainListApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/store/bargain/list",
    method: 'get',
    params: params
  });
}
/**
 * 砍价商品 新增
 */


function bargainSaveApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/store/bargain/save",
    method: 'POST',
    data: data
  });
}
/**
 * 砍价商品 详情
 */


function bargainInfoApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/store/bargain/info",
    method: 'get',
    params: params
  });
}
/**
 * 砍价商品 编辑
 */


function bargainUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/manghe/admin/store/bargain/update",
    method: 'post',
    params: params,
    data: data
  });
}
/**
 * 砍价商品 删除
 */


function bargainDeleteApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/store/bargain/delete",
    method: 'get',
    params: params
  });
}
/**
 * 砍价列表 详情
 */


function bargainOrderPinkApi(id) {
  return (0, _request.default)({
    url: "/manghe/admin/store/bargain/bargain_list/".concat(id),
    method: 'get'
  });
}
/**
 * 砍价列表 列表
 */


function bargainListListApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/store/bargain/bargain_list",
    method: 'get',
    params: params
  });
}
/**
 * 拼团商品 修改拼团状态
 */


function bargainStatusApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/store/bargain/update/status",
    method: 'post',
    params: params
  });
}
/**
 * 拼团商品 列表
 */


function combinationListApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/store/combination/list",
    method: 'get',
    params: params
  });
}
/**
 * 拼团商品 删除
 */


function combinationDeleteApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/store/combination/delete",
    method: 'get',
    params: params
  });
}
/**
 * 拼团商品 新增
 */


function combinationSaveApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/store/combination/save",
    method: 'post',
    data: data
  });
}
/**
 * 拼团商品 修改
 */


function combinationUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/manghe/admin/store/combination/update",
    method: 'post',
    params: params,
    data: data
  });
}
/**
 * 拼团商品 详情
 */


function combinationInfoApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/store/combination/info",
    method: 'get',
    params: params
  });
}
/**
 * 拼团商品 修改拼团状态
 */


function combinationStatusApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/store/combination/update/status",
    method: 'post',
    params: params
  });
}
/**
 * 拼团列表 列表
 */


function combineListApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/store/combination/combine/list",
    method: 'get',
    params: params
  });
}
/**
 * 拼团列表 统计
 */


function combineStatisticsApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/store/combination/statistics",
    method: 'get',
    params: params
  });
}
/**
 * 拼团列表 详情
 */


function combineOrderPinkApi(id) {
  return (0, _request.default)({
    url: "/manghe/admin/store/combination/order_pink/".concat(id),
    method: 'get'
  });
}
/**
 * 砍价 导出
 */


function exportBargainApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/export/excel/bargain/product",
    method: 'get',
    params: params
  });
}
/**
 * 拼团 导出
 */


function exportcombiantionApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/export/excel/combiantion/product",
    method: 'get',
    params: params
  });
}
/**
 * 视频号 草稿列表
 */


function draftListApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/pay/component/product/draft/list",
    method: 'get',
    params: params
  });
}
/**
 * 视频号 商品列表
 */


function videoProductListApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/pay/component/product/list",
    method: 'get',
    params: params
  });
}
/**
 * 视频号 类目
 */


function catListApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/pay/component/cat/get/list",
    method: 'get'
  });
}
/**
 * 视频号 添加
 */


function videoAddApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/pay/component/product/add",
    method: 'post',
    data: data
  });
}
/**
 * 视频号 编辑
 */


function videoUpdateApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/pay/component/product/update",
    method: 'post',
    data: data
  });
}
/**
 * 视频号 上架
 */


function videoUpApi(proId) {
  return (0, _request.default)({
    url: "/manghe/admin/pay/component/product/listing/".concat(proId),
    method: 'post'
  });
}
/**
 * 视频号 下架
 */


function videoDownApi(proId) {
  return (0, _request.default)({
    url: "/manghe/admin/pay/component/product/delisting/".concat(proId),
    method: 'post'
  });
}
/**
 * 视频号 删除
 */


function videoDelApi(proId) {
  return (0, _request.default)({
    url: "/manghe/admin/pay/component/product/delete/".concat(proId),
    method: 'post'
  });
}
/**
 * 视频号 草稿商品详情
 */


function draftInfoApi(id) {
  return (0, _request.default)({
    url: "/manghe/admin/pay/component/product/draft/get/".concat(id),
    method: 'get'
  });
}
/**
 * 视频号 草稿商品编辑
 */


function draftUpdateApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/pay/component/product/update",
    method: 'post',
    data: data
  });
}