"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _wxApi = require("@/api/wxApi");

var _ZBParser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/ZBParser"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "MyTemplates",
  components: {
    zbParser: _ZBParser.default
  },
  data: function data() {
    return {
      value: '',
      options: [{
        value: 'paySubscribe',
        label: '支付成功'
      }, {
        value: 'orderSubscribe',
        label: '订单相关'
      }, {
        value: 'extrctSubscribe',
        label: '提现消息'
      }, {
        value: 'orderRefundSubscribe',
        label: '订单退款'
      }, {
        value: 'rechargeSubscribe',
        label: '充值成功'
      }],
      labelPosition: 'right',
      isCreate: 0,
      editData: {},
      dialogVisible: false,
      switchData: this.$constants.switchStatus,
      tableFrom: {
        page: 1,
        limit: 20,
        status: null,
        title: null,
        tempId: null
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tempId: null
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    resetForm: function resetForm(formValue) {
      this.dialogVisible = false;
    },
    checkTemp: function checkTemp() {
      var _this = this;

      this.$modalSure('同步我的模板到小程序').then(function () {
        (0, _wxApi.tempAsyncApi)().then(function () {
          _this.$message.success('同步成功');
        });
      });
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    // 订单删除
    handleDelete: function handleDelete(row, idx) {
      var _this2 = this;

      this.$modalSure().then(function () {
        (0, _wxApi.wechatTemplateDeleteApi)(row.id).then(function () {
          _this2.$message.success('删除成功');

          _this2.tableData.data.splice(idx, 1);
        });
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.editData = {};
    },
    handlerSubmit: function handlerSubmit(formValue) {
      var _this3 = this;

      this.isCreate === 0 ? (0, _wxApi.wechatTemplateSaveApi)(formValue).then(function (data) {
        _this3.$message.success('新增成功');

        _this3.dialogVisible = false;
        _this3.editData = {};

        _this3.getList();
      }) : (0, _wxApi.wechatTemplateUpdateApi)(this.tempId, formValue).then(function (data) {
        _this3.$message.success('编辑成功');

        _this3.dialogVisible = false;

        _this3.getList();
      });
    },
    add: function add() {
      this.dialogVisible = true;
    },
    edit: function edit(row) {
      this.tempId = row.id;
      this.dialogVisible = true;
      this.isCreate = 1;
      this.editData = JSON.parse(JSON.stringify(row));
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;
      (0, _wxApi.myTempListApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list || [];
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 修改状态
    onchangeIsShow: function onchangeIsShow(row) {
      var _this5 = this;

      (0, _wxApi.myTempStatusApi)({
        status: row.status,
        id: row.id
      }).then(function () {
        _this5.$message.success('修改成功');

        _this5.getList();
      }).catch(function () {
        row.status = !row.status;
      });
    },
    // 修改场景
    onchangeType: function onchangeType(row) {
      var _this6 = this;

      (0, _wxApi.myTempTypeApi)({
        type: row.type,
        id: row.id
      }).then(function () {
        _this6.$message.success('修改成功');

        _this6.getList();
      });
    }
  }
};
exports.default = _default;