var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order_box",
      attrs: {
        visible: _vm.modals,
        title: "分配供应商",
        "before-close": _vm.handleClose,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.modals = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formItem",
          attrs: {
            model: _vm.formItem,
            "label-width": "110px",
            rules: _vm.rules,
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "供应商", prop: "supplier" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "80%" },
                  attrs: { filterable: "" },
                  on: {
                    change: function ($event) {
                      return _vm.onChangeExport(_vm.formItem.supplier)
                    },
                  },
                  model: {
                    value: _vm.formItem.supplier,
                    callback: function ($$v) {
                      _vm.$set(_vm.formItem, "supplier", $$v)
                    },
                    expression: "formItem.supplier",
                  },
                },
                _vm._l(_vm.express, function (item, i) {
                  return _c("el-option", {
                    key: i,
                    attrs: { value: item.value, label: item.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.putSend("formItem")
                },
              },
            },
            [_vm._v("提交")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.cancel("formItem")
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }