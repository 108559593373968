"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _wxApi = require("@/api/wxApi");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var _default = {
  name: "creatPublicTemplate",
  data: function data() {
    return {
      KeywordList: [],
      form: {
        checkList: [],
        kid: '',
        sceneDesc: '',
        tid: '',
        title: '',
        extra: ''
      },
      KeywordCheck: [],
      loading: false,
      loadingAll: false,
      rules: {
        sceneDesc: [{
          required: true,
          message: '请填写场景说明',
          trigger: 'blur'
        }],
        checkList: [{
          type: 'array',
          required: true,
          message: '请至少选择一个关键词',
          trigger: 'change'
        }]
      },
      tempRoute: {}
    };
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  mounted: function mounted() {
    this.getKeywordList();
    if (this.$route.params.id !== '0') this.getTitle();

    if (this.$route.params.myId !== '0') {
      this.setTagsViewTitle();
      this.wxInfo();
    }
  },
  methods: {
    // 设置tab标题
    setTagsViewTitle: function setTagsViewTitle() {
      var title = '编辑模板';
      var route = Object.assign({}, this.tempRoute, {
        title: "".concat(title, "-").concat(this.$route.params.myId)
      });
      this.$store.dispatch('tagsView/updateVisitedView', route);
    },
    handleChecked: function handleChecked(val) {
      this.KeywordCheck = this.KeywordList.filter(function (item) {
        return val.some(function (ele) {
          return ele == item.kid;
        });
      });
    },
    onSubmit: function onSubmit(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = {
            kid: _this.form.checkList.join(','),
            sceneDesc: _this.form.sceneDesc,
            tid: _this.$route.params.tid,
            title: _this.form.title,
            extra: JSON.stringify(_this.KeywordCheck)
          };
          _this.loading = true;
          _this.$route.params.myId === '0' ? (0, _wxApi.myTempSaveApi)(data).then(function (res) {
            _this.$message.success('提交成功');

            _this.$router.push('/appSetting/publicRoutine/publicRoutineTemplate');

            _this.loading = false;
          }).catch(function () {
            _this.loading = false;
          }) : (0, _wxApi.myTempUpdateApi)({
            id: _this.$route.params.myId
          }, data).then(function (res) {
            _this.$message.success('提交成功');

            _this.$router.push('/appSetting/publicRoutine/routineTemplate');

            _this.loading = false;
          }).catch(function () {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    closeCheck: function closeCheck(i) {
      this.form.checkList.splice(i, 1);
    },
    // 详情
    wxInfo: function wxInfo() {
      var _this2 = this;

      (0, _wxApi.myTempInfoApi)({
        id: this.$route.params.myId
      }).then(function (res) {
        _this2.form = {
          checkList: res.kid.split(',').map(Number),
          sceneDesc: res.sceneDesc,
          tid: res.tid,
          title: res.title,
          extra: res.extra
        };
        _this2.KeywordCheck = JSON.parse(res.extra);
      });
    },
    // 标题
    getTitle: function getTitle() {
      var _this3 = this;

      (0, _wxApi.publicTempInfoApi)({
        id: this.$route.params.id
      }).then(function (res) {
        _this3.form.title = res.title;
      });
    },
    // 关键字列表
    getKeywordList: function getKeywordList() {
      var _this4 = this;

      this.loadingAll = true;
      (0, _wxApi.getWeChatKeywordsByTidApi)({
        tid: this.$route.params.tid
      }).then(function (res) {
        _this4.KeywordList = res;

        for (var i = 0; i < res; i++) {
          _this4.$set(_this4.form.checkList, i, res[i]);
        }

        _this4.loadingAll = false;
      }).catch(function () {
        _this4.loadingAll = false;
      });
    },
    // 移动
    handleDragStart: function handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd: function handleDragEnd(e, item) {
      this.dragging = null;
    },
    handleDragOver: function handleDragOver(e) {
      e.dataTransfer.dropEffect = 'move';
    },
    handleDragEnter: function handleDragEnter(e, item) {
      e.dataTransfer.effectAllowed = 'move';

      if (item === this.dragging) {
        return;
      }

      var newItems = _toConsumableArray(this.KeywordCheck);

      var src = newItems.indexOf(this.dragging);
      var dst = newItems.indexOf(item);
      newItems.splice.apply(newItems, [dst, 0].concat(_toConsumableArray(newItems.splice(src, 1))));
      this.KeywordCheck = newItems;
    }
  }
};
exports.default = _default;