var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.orderDatalist
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "订单信息",
                visible: _vm.dialogVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "description",
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("用户信息")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "acea-row" }, [
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v("用户昵称：" + _vm._s(_vm.orderDatalist.nikeName)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        "绑定电话：" +
                          _vm._s(
                            _vm.orderDatalist.phone
                              ? _vm.orderDatalist.phone
                              : "无"
                          )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.orderDatalist.statusStr.key === "toBeWrittenOff"
                          ? "提货信息"
                          : "收货信息"
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "acea-row" }, [
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        _vm._s(
                          _vm.orderDatalist.statusStr.key === "toBeWrittenOff"
                            ? "提货人"
                            : "收货人"
                        ) +
                          "：" +
                          _vm._s(_vm.orderDatalist.realName)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        _vm._s(
                          _vm.orderDatalist.statusStr.key === "toBeWrittenOff"
                            ? "提货电话"
                            : "收货电话"
                        ) +
                          "：" +
                          _vm._s(_vm.orderDatalist.userPhone)
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.orderDatalist.statusStr.key !== "toBeWrittenOff"
                      ? _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            _vm._s(
                              _vm.orderDatalist.statusStr.key ===
                                "toBeWrittenOff"
                                ? "提货地址"
                                : "收货地址"
                            ) +
                              "：" +
                              _vm._s(_vm.orderDatalist.userAddress)
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("订单信息")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "acea-row" },
                    [
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "订单编号：" + _vm._s(_vm.orderDatalist.orderId)
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "description-term",
                          staticStyle: { color: "red" },
                        },
                        [
                          _vm._v(
                            "订单状态：" +
                              _vm._s(_vm.orderDatalist.statusStr.value)
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "商品总数：" + _vm._s(_vm.orderDatalist.totalNum)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "商品总价：" + _vm._s(_vm.orderDatalist.proTotalPrice)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "支付邮费：" + _vm._s(_vm.orderDatalist.payPostage)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "优惠券金额：" + _vm._s(_vm.orderDatalist.couponPrice)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "实际支付：" + _vm._s(_vm.orderDatalist.payPrice)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "抵扣金额：" +
                            _vm._s(_vm.orderDatalist.deductionPrice)
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.orderDatalist.refundPrice
                        ? _c(
                            "div",
                            { staticClass: "description-term fontColor3" },
                            [
                              _vm._v(
                                "退款金额：" +
                                  _vm._s(_vm.orderDatalist.refundPrice)
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.orderDatalist.useIntegral
                        ? _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "使用积分：" +
                                _vm._s(_vm.orderDatalist.useIntegral)
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.orderDatalist.backIntegral
                        ? _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "退回积分：" +
                                _vm._s(_vm.orderDatalist.backIntegral)
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "创建时间：" + _vm._s(_vm.orderDatalist.createTime)
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.orderDatalist.refundReasonTime
                        ? _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "退款时间：" +
                                _vm._s(_vm.orderDatalist.refundReasonTime)
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "支付方式：" + _vm._s(_vm.orderDatalist.payTypeStr)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "推广人：" +
                            _vm._s(
                              _vm._f("filterEmpty")(
                                _vm.orderDatalist.spreadName
                              )
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.orderDatalist.shippingType === 2 &&
                      _vm.orderDatalist.statusStr.key === "notShipped"
                        ? _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "门店名称：" + _vm._s(_vm.orderDatalist.storeName)
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.orderDatalist.shippingType === 2 &&
                      _vm.orderDatalist.statusStr.key === "notShipped"
                        ? _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "核销码：" + _vm._s(_vm.orderDatalist.user_phone)
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v("商家备注：" + _vm._s(_vm.orderDatalist.remark)),
                      ]),
                      _vm._v(" "),
                      _vm.orderDatalist.statusStr.key === "toBeWrittenOff" &&
                      _vm.orderDatalist.systemStore
                        ? [
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "提货码：" +
                                  _vm._s(_vm.orderDatalist.verifyCode)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "门店名称：" +
                                  _vm._s(_vm.orderDatalist.systemStore.name)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "门店电话：" +
                                  _vm._s(_vm.orderDatalist.systemStore.phone)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "门店地址：" +
                                  _vm._s(
                                    _vm.orderDatalist.systemStore.address +
                                      _vm.orderDatalist.systemStore
                                        .detailedAddress
                                  )
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.orderDatalist.deliveryType === "express"
                    ? [
                        _c("el-divider"),
                        _vm._v(" "),
                        _c("div", { staticClass: "title" }, [
                          _vm._v("物流信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "快递公司：" +
                                _vm._s(_vm.orderDatalist.deliveryName)
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "description-term" },
                            [
                              _vm._v(
                                "快递单号：" +
                                  _vm._s(_vm.orderDatalist.deliveryId)
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "5px" },
                                  attrs: { type: "primary", size: "mini" },
                                  on: { click: _vm.openLogistics },
                                },
                                [_vm._v("物流查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderDatalist.deliveryType === "send"
                    ? [
                        _c("el-divider"),
                        _vm._v(" "),
                        _c("div", { staticClass: "title" }, [
                          _vm._v("配送信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "送货人姓名：" +
                                _vm._s(_vm.orderDatalist.deliveryName)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "送货人电话：" +
                                _vm._s(_vm.orderDatalist.deliveryId)
                            ),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderDatalist.mark
                    ? [
                        _c("el-divider"),
                        _vm._v(" "),
                        _c("div", { staticClass: "title" }, [
                          _vm._v("用户备注"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(_vm._s(_vm.orderDatalist.mark)),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.orderDatalist
        ? _c(
            "el-dialog",
            {
              attrs: { title: "提示", visible: _vm.modal2, width: "30%" },
              on: {
                "update:visible": function ($event) {
                  _vm.modal2 = $event
                },
              },
            },
            [
              _c("div", { staticClass: "logistics acea-row row-top" }, [
                _c("div", { staticClass: "logistics_img" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/imgs/expressi.jpg") },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "logistics_cent" }, [
                  _c("span", { staticClass: "mb10" }, [
                    _vm._v(
                      "物流公司：" + _vm._s(_vm.orderDatalist.deliveryName)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("物流单号：" + _vm._s(_vm.orderDatalist.deliveryId)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "acea-row row-column-around trees-coadd" },
                [
                  _c(
                    "div",
                    { staticClass: "scollhide" },
                    [
                      _c(
                        "el-timeline",
                        { attrs: { reverse: _vm.reverse } },
                        _vm._l(_vm.result, function (item, i) {
                          return _c("el-timeline-item", { key: i }, [
                            _c("p", {
                              staticClass: "time",
                              domProps: { textContent: _vm._s(item.time) },
                            }),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "content",
                              domProps: { textContent: _vm._s(item.status) },
                            }),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.modal2 = false
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }