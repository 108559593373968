"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _creatTemplates = _interopRequireDefault(require("./creatTemplates"));

var logistics = _interopRequireWildcard(require("@/api/logistics.js"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ShippingTemplates',
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        true: '开启',
        false: '关闭'
      };
      return statusMap[status];
    },
    typeFilter: function typeFilter(status) {
      var statusMap = {
        1: '按件数',
        2: '按重量',
        3: '按体积'
      };
      return statusMap[status];
    }
  },
  components: {
    CreatTemplates: _creatTemplates.default
  },
  data: function data() {
    return {
      isShow: false,
      dialogVisible: false,
      form: {
        keywords: ''
      },
      tableData: '',
      page: 1,
      limit: 20,
      loading: false
    };
  },
  created: function created() {
    this.getDataList();
  },
  methods: {
    // 添加
    handleSubmit: function handleSubmit() {
      this.$refs.addTemplates.dialogVisible = true;
      this.$refs.addTemplates.getCityList();
      this.$refs.addTemplates.changType(0);
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getDataList();
    },
    // 分页
    pageChange: function pageChange(e) {
      this.page = e;
      this.getDataList();
    },
    handleSizeChange: function handleSizeChange(e) {
      this.limit = e;
      this.getDataList();
    },
    // 数据列表
    getDataList: function getDataList() {
      var _this = this;

      this.loading = true;
      logistics.shippingTemplatesList({
        keywords: this.form.keywords,
        page: this.page,
        limit: this.limit
      }).then(function (res) {
        _this.loading = false;
        _this.tableData = res;
      });
    },
    // 编辑
    bindEdit: function bindEdit(item) {
      this.$refs.addTemplates.getCityList();
      this.$refs.addTemplates.getInfo(item.id, item.appoint);
      this.$refs.addTemplates.changType(1);
    },
    // 删除
    bindDelete: function bindDelete(item) {
      var _this2 = this;

      this.$modalSure().then(function () {
        logistics.shippingDetete({
          id: item.id
        }).then(function (res) {
          _this2.$message.success('删除成功');

          _this2.getDataList();
        });
      }); // logistics.shippingDetete()
    },
    getList: function getList() {
      this.getDataList();
    }
  }
};
exports.default = _default;