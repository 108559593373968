"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.captchaApi = captchaApi;
exports.foundsApi = foundsApi;
exports.getInfo = getInfo;
exports.getLoginPicApi = getLoginPicApi;
exports.groupDeleteApi = groupDeleteApi;
exports.groupInfoApi = groupInfoApi;
exports.groupListApi = groupListApi;
exports.groupPiApi = groupPiApi;
exports.groupSaveApi = groupSaveApi;
exports.groupUpdateApi = groupUpdateApi;
exports.infobyconditionApi = infobyconditionApi;
exports.levelDeleteApi = levelDeleteApi;
exports.levelInfoApi = levelInfoApi;
exports.levelListApi = levelListApi;
exports.levelSaveApi = levelSaveApi;
exports.levelUpdateApi = levelUpdateApi;
exports.levelUseApi = levelUseApi;
exports.login = login;
exports.logout = logout;
exports.tagDeleteApi = tagDeleteApi;
exports.tagInfoApi = tagInfoApi;
exports.tagListApi = tagListApi;
exports.tagPiApi = tagPiApi;
exports.tagSaveApi = tagSaveApi;
exports.tagUpdateApi = tagUpdateApi;
exports.topdetailApi = topdetailApi;
exports.updatePhoneApi = updatePhoneApi;
exports.updateSpreadApi = updateSpreadApi;
exports.userDeleteApi = userDeleteApi;
exports.userInfoApi = userInfoApi;
exports.userLevelUpdateApi = userLevelUpdateApi;
exports.userListApi = userListApi;
exports.userUpdateApi = userUpdateApi;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function login(data) {
  return (0, _request.default)({
    url: '/manghe/admin/login',
    method: 'post',
    data: data
  });
}

function getInfo(token) {
  return (0, _request.default)({
    url: '/manghe/admin/getAdminInfoByToken',
    method: 'get',
    params: {
      token: token
    }
  });
}

function logout() {
  return (0, _request.default)({
    url: '/manghe/admin/logout',
    method: 'get'
  });
}
/**
 * 会员管理 列表
 * @param pram
 */


function userListApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/list",
    method: 'get',
    params: params
  });
}
/**
 * 会员管理 修改
 * @param pram
 */


function userUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/manghe/admin/user/update",
    method: 'post',
    params: params,
    data: data
  });
}
/**
 * 会员管理等级 修改
 * @param pram
 */


function userLevelUpdateApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/user/update/level",
    method: 'post',
    data: data
  });
}
/**
 * 会员管理 详情
 * @param pram
 */


function userInfoApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/info",
    method: 'get',
    params: params
  });
}
/**
 * 会员管理 账户详情
 * @param pram
 */


function infobyconditionApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/infobycondition",
    method: 'get',
    params: params
  });
}
/**
 * 会员管理 账户详情top数据
 * @param pram
 */


function topdetailApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/topdetail",
    method: 'get',
    params: params
  });
}
/**
 * 会员管理 批量设置分组
 * @param pram
 */


function groupPiApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/group",
    method: 'post',
    params: params
  });
}
/**
 * 会员管理 批量设置标签
 * @param pram
 */


function tagPiApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/tag",
    method: 'post',
    params: params
  });
}
/**
 * 会员管理 积分余额
 * @param pram
 */


function foundsApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/operate/founds",
    method: 'get',
    params: params
  });
}
/**
 * 会员管理 删除
 * @param pram
 */


function userDeleteApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/delete",
    method: 'get',
    params: params
  });
}
/**
 * 会员等级 列表
 * @param pram
 */


function levelListApi() {
  return (0, _request.default)({
    url: "/manghe/admin/system/user/level/list",
    method: 'get'
  });
}
/**
 * 会员等级 新增
 * @param pram
 */


function levelSaveApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/system/user/level/save",
    method: 'post',
    data: data
  });
}
/**
 * 会员等级 编辑
 *  @param pram
 */


function levelUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/manghe/admin/system/user/level/update/".concat(params),
    method: 'post',
    // params,
    data: data
  });
}
/**
 * 会员等级 详情
 * @param pram
 */


function levelInfoApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/system/user/level/info",
    method: 'get',
    params: params
  });
}
/**
 * 会员等级 删除
 * @param pram
 */


function levelDeleteApi(id) {
  return (0, _request.default)({
    url: "/manghe/admin/system/user/level/delete/".concat(id),
    method: 'post'
  });
}
/**
 * 会员等级 是否显示
 * @param pram
 */


function levelUseApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/system/user/level/use",
    method: 'post',
    data: data
  });
}
/**
 * 会员标签 列表
 * @param pram
 */


function tagListApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/tag/list",
    method: 'get',
    params: params
  });
}
/**
 * 会员标签 新增
 * @param pram
 */


function tagSaveApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/user/tag/save",
    method: 'post',
    data: data
  });
}
/**
 * 会员标签 编辑
 * @param pram
 */


function tagUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/manghe/admin/user/tag/update",
    method: 'post',
    params: params,
    data: data
  });
}
/**
 * 会员标签 详情
 * @param pram
 */


function tagInfoApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/tag/info",
    method: 'get',
    params: params
  });
}
/**
 * 会员标签 删除
 * @param pram
 */


function tagDeleteApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/tag/delete",
    method: 'get',
    params: params
  });
}
/**
 * 会员分组 列表
 * @param pram
 */


function groupListApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/group/list",
    method: 'get',
    params: params
  });
}
/**
 * 会员分组 新增
 * @param pram
 */


function groupSaveApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/user/group/save",
    method: 'post',
    data: data
  });
}
/**
 * 会员分组 编辑
 * @param pram
 */


function groupUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/manghe/admin/user/group/update",
    method: 'post',
    params: params,
    data: data
  });
}
/**
 * 会员分组 详情
 * @param pram
 */


function groupInfoApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/group/info",
    method: 'get',
    params: params
  });
}
/**
 * 会员分组 删除
 * @param pram
 */


function groupDeleteApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/group/delete",
    method: 'get',
    params: params
  });
}
/**
 *获取登录页图片
 */


function getLoginPicApi() {
  return (0, _request.default)({
    url: "/manghe/admin/getLoginPic",
    method: 'get'
  });
}
/**
 * @description 验证码
 */


function captchaApi() {
  return (0, _request.default)({
    url: "/manghe/admin/validate/code/get",
    method: 'get'
  });
}
/**
 * @description 修改上级推广人
 */


function updateSpreadApi(data) {
  return (0, _request.default)({
    url: "/manghe/admin/user/update/spread",
    method: 'post',
    data: data
  });
}
/**
 * @description 修改手机号
 */


function updatePhoneApi(params) {
  return (0, _request.default)({
    url: "/manghe/admin/user/update/phone",
    method: 'get',
    params: params
  });
}