"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var logistics = _interopRequireWildcard(require("@/api/logistics"));

var _elementUi = require("element-ui");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var defaultRole = {
  name: '',
  type: 1,
  appoint: false,
  sort: 0,
  region: [{
    first: 0,
    firstPrice: 0,
    renewal: 0,
    renewalPrice: 0,
    city_ids: []
  }],
  undelivery: 0,
  free: [],
  undelives: {},
  city_id3: []
};
var kg = '重量（kg）';
var m = '体积（m³）';
var statusMap = [{
  title: '首件',
  title2: '续件',
  title3: '包邮件数'
}, {
  title: "\u9996\u4EF6".concat(kg),
  title2: "\u7EED\u4EF6".concat(kg),
  title3: "\u5305\u90AE".concat(kg)
}, {
  title: "\u9996\u4EF6".concat(m),
  title2: "\u7EED\u4EF6".concat(m),
  title3: "\u5305\u90AE".concat(m)
}];
var _default = {
  name: 'CreatTemplates',
  components: {},
  data: function data() {
    return {
      loading: false,
      rules: {
        name: [{
          required: true,
          message: '请输入模板名称',
          trigger: 'blur'
        }],
        free: [{
          type: 'array',
          required: true,
          message: '请至少添加一个地区',
          trigger: 'change'
        }],
        appoint: [{
          required: true,
          message: '请选择是否指定包邮',
          trigger: 'change'
        }],
        undelivery: [{
          required: true,
          message: '请选择是否指定区域不配送',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择计费方式',
          trigger: 'change'
        }],
        region: [{
          required: true,
          message: '请选择活动区域',
          trigger: 'change'
        }],
        city_id3: [{
          type: 'array',
          required: true,
          message: '请至少选择一个地区',
          trigger: 'change'
        }],
        first: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        renewal: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        firstPrice: [{
          required: true,
          message: '请输入运费',
          trigger: 'blur'
        }],
        renewalPrice: [{
          required: true,
          message: '请输入续费',
          trigger: 'blur'
        }]
      },
      nodeKey: 'city_id',
      props: {
        children: 'child',
        label: 'name',
        value: 'cityId',
        multiple: true
      },
      dialogVisible: false,
      ruleForm: Object.assign({}, defaultRole),
      listLoading: false,
      cityList: [],
      columns: {
        title: '首件',
        title2: '续件',
        title3: '包邮件数'
      },
      tempId: 0,
      type: 0 // 0添加 1编辑

    };
  },
  mounted: function mounted() {
    var _this = this;

    setTimeout(function () {
      var cityList = JSON.parse(sessionStorage.getItem('cityList'));
      _this.cityList = cityList;
    }, 1000);
  },
  methods: {
    changType: function changType(type) {
      this.type = type;
    },
    onClose: function onClose(formName) {
      this.dialogVisible = false;
      this.$refs[formName].resetFields();
    },
    confirmEdit: function confirmEdit(row, index) {
      row.splice(index, 1);
    },
    popoverHide: function popoverHide() {},
    handleClose: function handleClose() {
      // this.$refs['ruleForm'].resetFields()
      this.dialogVisible = false;
      this.ruleForm = {
        name: '',
        type: 1,
        appoint: false,
        sort: 0,
        region: [{
          first: 0,
          firstPrice: 0,
          renewal: 0,
          renewalPrice: 0,
          city_ids: []
        }],
        undelivery: 0,
        free: [],
        undelives: {},
        city_id3: []
      };
    },
    changeRegion: function changeRegion(value) {
      console.log(value);
    },
    changeRadio: function changeRadio(num) {
      this.columns = Object.assign({}, statusMap[num - 1]);
    },
    // 添加配送区域
    addRegion: function addRegion(region) {
      region.push(Object.assign({}, {
        first: 0,
        firstPrice: 0,
        renewal: 0,
        renewalPrice: 0,
        city_ids: []
      }));
    },
    addFree: function addFree(Free) {
      Free.push(Object.assign({}, {
        city_id: [],
        number: 1,
        price: 1,
        city_ids: []
      }));
    },

    /**
     * 详情
     * id 模板id
     * appoint true包邮 false不包邮
    **/
    getInfo: function getInfo(id, appoint) {
      var _this2 = this;

      this.tempId = id;

      var loadingInstance = _elementUi.Loading.service({
        fullscreen: true
      });

      logistics.templateDetailApi({
        id: id
      }).then(function (res) {
        _this2.dialogVisible = true;
        var info = res;
        _this2.ruleForm = Object.assign(_this2.ruleForm, {
          name: info.name,
          type: info.type,
          appoint: info.appoint,
          sort: info.sort
        });
        _this2.columns = Object.assign({}, statusMap[_this2.ruleForm.type - 1]);

        _this2.$nextTick(function () {
          loadingInstance.close();
        }); // 不包邮地区


        _this2.shippingRegion(); // 包邮地区


        if (info.appoint) {
          _this2.shippingFree();
        }
      }).catch(function (res) {
        // console.integralLog(res)
        _this2.$message.error(res.message);

        _this2.$nextTick(function () {
          loadingInstance.close();
        });
      });
    },
    // 不包邮
    shippingRegion: function shippingRegion() {
      var _this3 = this;

      logistics.shippingRegion({
        tempId: this.tempId
      }).then(function (res) {
        res.forEach(function (item, index) {
          item.title = JSON.parse(item.title);
          item.city_ids = item.title;
        });
        _this3.ruleForm.region = res;
      });
    },
    // 包邮
    shippingFree: function shippingFree() {
      var _this4 = this;

      logistics.shippingFree({
        tempId: this.tempId
      }).then(function (res) {
        res.forEach(function (item, index) {
          item.title = JSON.parse(item.title);
          item.city_ids = item.title;
        });
        _this4.ruleForm.free = res;
      });
    },
    // 列表
    getCityList: function getCityList() {
      var _this5 = this;

      logistics.cityListTree().then(function (res) {
        // res.forEach((el, index) => {
        //   el.child.forEach((cel, j) => {
        //     delete cel.child
        //   })
        // })
        sessionStorage.setItem('cityList', JSON.stringify(res));
        var cityList = JSON.parse(sessionStorage.getItem('cityList'));
        _this5.cityList = cityList;
      }).catch(function (res) {
        _this5.$message.error(res.message);
      });
    },
    change: function change(idBox) {
      idBox.map(function (item) {
        var ids = [];
        item.city_ids.map(function (j) {
          j.splice(0, 1);
          ids.push(j[0]);
        });
        item.city_id = ids;
      });
      return idBox;
    },
    changeOne: function changeOne(idBox) {
      var city_ids = [];
      idBox.map(function (item) {
        item.splice(0, 1);
        city_ids.push(item[0]);
      });
      return city_ids;
    },
    onsubmit: function onsubmit(formName) {
      var _this6 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this6.loading = true;
          var param = {
            appoint: _this6.ruleForm.appoint,
            name: _this6.ruleForm.name,
            sort: _this6.ruleForm.sort,
            type: _this6.ruleForm.type // 配送区域及运费
            // shippingTemplatesRegionRequestList: [],
            // // 指定包邮设置
            // shippingTemplatesFreeRequestList: []

          };

          _this6.ruleForm.region.forEach(function (el, index) {
            el.title = el.city_ids.length > 0 ? JSON.stringify(el.city_ids) : JSON.stringify([[0, 0]]);

            for (var i = 0; i < el.city_ids.length; i++) {
              el.city_ids[i].shift();
            }

            el.cityId = el.city_ids.length > 0 ? el.city_ids.join(',') : 'all';
          });

          param.shippingTemplatesRegionRequestList = _this6.ruleForm.region;
          param.shippingTemplatesRegionRequestList.forEach(function (el, index) {// delete el.city_ids
            // delete el.city_id
          });

          if (_this6.ruleForm.appoint) {
            _this6.ruleForm.free.forEach(function (el, index) {
              el.title = el.city_ids.length > 0 ? JSON.stringify(el.city_ids) : JSON.stringify([[0, 0]]);

              for (var i = 0; i < el.city_ids.length; i++) {
                el.city_ids[i].shift();
              }

              el.cityId = el.city_ids.length > 0 ? el.city_ids.join(',') : 'all';
            });

            param.shippingTemplatesFreeRequestList = _this6.ruleForm.free;
            param.shippingTemplatesFreeRequestList.forEach(function (el, index) {// delete el.city_ids
              // delete el.city_id
            });
          }

          if (_this6.type === 0) {
            logistics.shippingSave(param).then(function (res) {
              _this6.$message.success('操作成功');

              _this6.handleClose();

              _this6.$nextTick(function () {
                _this6.dialogVisible = false;
              });

              setTimeout(function () {
                _this6.$emit('getList');
              }, 600);
              _this6.loading = false;
            });
          } else {
            logistics.shippingUpdate(param, {
              id: _this6.tempId
            }).then(function (res) {
              _this6.$message.success('操作成功');

              setTimeout(function () {
                _this6.$emit('getList');

                _this6.handleClose();
              }, 600);

              _this6.$nextTick(function () {
                _this6.dialogVisible = false;
              });

              _this6.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    clear: function clear() {
      this.ruleForm.name = '';
      this.ruleForm.sort = 0;
    }
  }
};
exports.default = _default;