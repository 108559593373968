"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.validatorDefaultCatch = validatorDefaultCatch;

var _dialog = require("vue-ydui/dist/lib.rem/dialog");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var dialog = {
  confirm: _dialog.Confirm,
  alert: _dialog.Alert,
  toast: _dialog.Toast,
  notify: _dialog.Notify,
  loading: _dialog.Loading
};
var icons = {
  error: "操作失败",
  success: "操作成功"
};
Object.keys(icons).reduce(function (dialog, key) {
  dialog[key] = function (mes) {
    var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return new Promise(function (resolve) {
      (0, _dialog.Toast)(_objectSpread({
        mes: mes || icons[key],
        timeout: 1000,
        icon: key,
        callback: function callback() {
          resolve();
        }
      }, obj));
    });
  };

  return dialog;
}, dialog);

dialog.message = function () {
  var mes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "操作失败";
  var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise(function (resolve) {
    (0, _dialog.Toast)(_objectSpread({
      mes: mes,
      timeout: 1000,
      callback: function callback() {
        resolve();
      }
    }, obj));
  });
};

dialog.validateError = function () {
  validatorDefaultCatch.apply(void 0, arguments);
};

function validatorDefaultCatch(err) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "message";
  console.log(err);
  return dialog[type](err.errors[0].message);
}

var _default = dialog;
exports.default = _default;