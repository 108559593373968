"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _router = require("@/router");

var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));

var _list = _interopRequireDefault(require("@/components/Category/list"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
var _default = {
  // name: "index"
  components: {
    categoryList: _list.default
  },
  data: function data() {
    return {
      asyncRoutes: _router.asyncRoutes,
      constants: this.$constants
    };
  },
  mounted: function mounted() {},
  methods: {
    categoryAdd: function categoryAdd(pram) {
      var _this = this;

      var _pram = {
        extra: pram.extra,
        name: pram.name,
        pid: pram.pid,
        sort: pram.sort,
        status: pram.status,
        type: pram.type,
        url: pram.url,
        hot: parm.hot
      };
      categoryApi.addCategroy(_pram).then(function (data) {
        _this.$message.success('添加菜单成功');
      });
    }
  }
};
exports.default = _default;