"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var systemAdminApi = _interopRequireWildcard(require("@/api/systemadmin.js"));

var roleApi = _interopRequireWildcard(require("@/api/role.js"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "index",
  data: function data() {
    return {
      constants: this.$constants,
      loading: false,
      templateRadio: '',
      dialogFormVisible: false,
      tableData: [],
      artFrom: {
        page: 1,
        limit: 20,
        status: 1,
        realName: '',
        roles: ''
      },
      total: 0,
      timeVal: '',
      roleList: []
    };
  },
  created: function created() {
    this.handleGetRoleList();
  },
  methods: {
    handleGetRoleList: function handleGetRoleList() {
      var _this = this;

      var _pram = {
        page: 1,
        limit: 9999
      };
      roleApi.getRoleList(_pram).then(function (data) {
        _this.roleList = data;
      });
    },
    getTemplateRow: function getTemplateRow(row) {
      this.dialogFormVisible = false;
      this.$emit("upImgUid", row);
    },
    tableList: function tableList() {
      var _this2 = this;

      this.loading = true;
      systemAdminApi.adminList(this.artFrom).then(function (data) {
        _this2.tableData = data.list;
        _this2.total = data.total;
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      }); // let that = this;
      // that.loading = true;
      // userListApi(that.artFrom).then(res=>{
      //   that.loading = false;
      //   that.tableData = res.list;
      //   that.total = res.total
      // })
    },
    //切换显示条数
    sizeChange: function sizeChange(index) {
      this.artFrom.limit = index;
      this.tableList();
    },
    //切换页数
    pageChange: function pageChange(index) {
      this.artFrom.page = index;
      this.tableList();
    },
    onchangeTime: function onchangeTime(e) {
      this.artFrom.page = 1;

      if (e !== null) {
        this.artFrom.data = e.join(',');
      } else {
        this.artFrom.data = '';
      }

      this.tableList();
    },
    search: function search() {
      this.timeVal = '';
      this.artFrom.page = 1;
      this.tableList();
    },
    cancel: function cancel() {
      this.artFrom = {
        page: 1,
        limit: 20,
        data: '',
        realName: ''
      };
      this.timeVal = '';
      this.templateRadio = '';
    }
  }
};
exports.default = _default;