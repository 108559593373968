"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Parser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/Parser"));

var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));

var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));

var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));

var systemSettingApi = _interopRequireWildcard(require("@/api/systemSetting.js"));

var systemConfigApi = _interopRequireWildcard(require("@/api/systemConfig.js"));

var _index = _interopRequireDefault(require("@/views/appSetting/wxAccount/wxTemplate/index"));

var _utils = require("@/components/FormGenerator/utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: "index",
  components: {
    Template: _index.default,
    parser: _Parser.default
  },
  data: function data() {
    return {
      loading: false,
      formConf: {
        content: {
          fields: []
        },
        id: null,
        render: false,
        isEdit: false
      },
      formConfChild: {
        content: {
          fields: []
        },
        id: null,
        render: false,
        isEdit: false
      },
      activeNamel1: null,
      activeNamel2: '',
      //针对文件特殊处理
      treeList: [],
      editDataChild: {},
      isCreate: 0,
      currentEditId: null,
      currentEditData: null,
      currentSelectedUploadFlag: null
    };
  },
  mounted: function mounted() {
    this.handlerGetTreeList();
    this.getCurrentUploadSelectedFlag();
  },
  methods: {
    // handleTabClick(tab, event) {
    //   if (tab.name) {
    //     this.handlerGetLevel1FormConfig(tab.name)
    //   } else if (tab.$children.length > 0 ) { // 初次加载第二层的第一个Tab数据
    //     // if(tab.$children[0].panes){ // todo 优化。。。
    //     //   this.activeNamel2 = tab.$children[0].panes[0].name
    //     // }else{
    //     //   conaole.integralLog()
    //     // }
    //     let _selected = tab.$children[0].panes[0]
    //     // 设置特殊处理的文件长传表单默认选中第一个tab
    //     this.activeNamel2 = _selected.name != 72 ? _selected.name : _selected.label
    //     if(this.activeNamel2 == 108){
    //       switch (this.currentSelectedUploadFlag) {
    //         case 1:
    //           this.activeNamel2 = '本地(不推荐)'
    //           this.handlerGetLevel2FormConfig(108)
    //           break
    //         case 2:
    //           this.activeNamel2 = '阿里云配置'
    //           this.handlerGetLevel2FormConfig(81)
    //           break
    //         case 3:
    //           this.activeNamel2 = '七牛云配置'
    //           this.handlerGetLevel2FormConfig(82)
    //           break
    //         case 4:
    //           this.activeNamel2 = '腾讯云配置'
    //           this.handlerGetLevel2FormConfig(83)
    //           break
    //       }
    //     }else{
    //       this.handlerGetLevel2FormConfig(_selected.name)
    //     }
    //
    //   }
    // },
    handleTabClick: function handleTabClick(tab) {
      this.activeNamel2 = tab.$children[0].panes[0].name;
      this.handlerGetLevel2FormConfig(this.activeNamel2);
    },
    handlerGetLevel1FormConfig: function handlerGetLevel1FormConfig(id) {
      var _this = this;

      var formPram = {
        id: id
      };
      this.currentEditId = id;
      this.formConf.content = {
        fields: []
      };
      this.formConf.render = false;
      this.loading = true;
      systemFormConfigApi.getFormConfigInfo(formPram).then(function (data) {
        var id = data.id,
            name = data.name,
            info = data.info,
            content = data.content;
        _this.formConf.content = JSON.parse(content);
        _this.formConf.id = id;

        _this.handlerGetSettingInfo(id, 1);

        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    handleItemTabClick: function handleItemTabClick(tab, event) {
      //这里对tabs=tab.name和radio=id做了兼容
      var _id = tab.name ? tab.name : tab;

      if (!_id) return this.$message.error('表单配置不正确，请关联正确表单后使用');
      this.handlerGetLevel2FormConfig(_id);
    },
    handlerGetLevel2FormConfig: function handlerGetLevel2FormConfig(id) {
      var _this2 = this;

      var formPram = {
        id: id
      };
      this.currentEditId = id;
      this.formConfChild.content = {
        fields: []
      };
      this.formConfChild.render = false;
      this.loading = true;
      systemFormConfigApi.getFormConfigInfo(formPram).then(function (data) {
        var id = data.id,
            name = data.name,
            info = data.info,
            content = data.content;
        _this2.formConfChild.content = JSON.parse(content);
        _this2.formConfChild.id = id;

        _this2.handlerGetSettingInfo(id, 2);

        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    },
    handlerGetSettingInfo: function handlerGetSettingInfo(id, level) {
      var _this3 = this;

      systemSettingApi.systemConfigInfo({
        id: id
      }).then(function (data) {
        _this3.currentEditData = data;

        if (level === 1) {
          _this3.formConf.isEdit = _this3.currentEditData !== null;
          _this3.formConf.render = true;
        } else {
          _this3.formConfChild.isEdit = _this3.currentEditData !== null;
          _this3.formConfChild.render = true;
        }
      });
    },
    handlerSubmit: function handlerSubmit(formValue) {
      this.handlerSave(formValue);
    },
    handlerSave: function handlerSave(formValue) {
      var _this4 = this;

      var _pram = this.buildFormPram(formValue);

      var _formId = 0;
      systemSettingApi.systemConfigSave(_pram).then(function (data) {
        _this4.$message.success('添加数据成功');
      });
    },
    handlerGetTreeList: function handlerGetTreeList() {
      var _this5 = this;

      var _pram = {
        type: this.$constants.categoryType[5].value,
        status: 1
      };
      this.loading = true;
      categoryApi.treeCategroy(_pram).then(function (data) {
        _this5.treeList = _this5.handleAddArrt(data);
        if (_this5.treeList.length > 0) _this5.activeNamel1 = _this5.treeList[0].id.toString();

        if (_this5.treeList.length > 0 && _this5.treeList[0].child.length > 0) {
          _this5.activeNamel2 = _this5.treeList[0].child[0].extra;
        }

        if (_this5.activeNamel2) {
          _this5.handlerGetLevel2FormConfig(_this5.treeList[0].child[0].extra);
        } // else {
        //  this.handlerGetLevel1FormConfig(this.treeList[0].extra)
        //}


        _this5.loading = false;
      }).catch(function () {
        _this5.loading = false;
      });
    },
    handleAddArrt: function handleAddArrt(treeData) {
      // let _result = this.addTreeListLabel(treeData)
      var _result = selfUtil.addTreeListLabel(treeData);

      return _result;
    },
    buildFormPram: function buildFormPram(formValue) {
      var _pram = {
        fields: [],
        id: this.currentEditId,
        sort: 0,
        // 参数暂时无用
        status: true // 参数暂时无用

      };
      var _fields = [];
      Object.keys(formValue).forEach(function (key) {
        _fields.push({
          name: key,
          title: key,
          value: formValue[key]
        });
      });
      _pram.fields = _fields;
      return _pram;
    },
    getCurrentUploadSelectedFlag: function getCurrentUploadSelectedFlag() {
      var _this6 = this;

      systemConfigApi.configGetUniq({
        key: "uploadType"
      }).then(function (data) {
        _this6.currentSelectedUploadFlag = parseInt(data);
      });
    }
  }
};
exports.default = _default;