"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));

var _Parser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/Parser"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
   * 注意：和Parser唯一的区别就是这里仅仅传入表单配置id即可自动加载已配置的表单
   *      数据后渲染表单，
   *      其他业务和Parser保持一致
   */
var _default = {
  // name: "ZBParser"
  components: {
    parser: _Parser.default
  },
  props: {
    formId: {
      type: Number,
      required: true
    },
    isCreate: {
      type: Number,
      default: 0 // 0=create 1=edit

    },
    editData: {
      type: Object
    }
  },
  data: function data() {
    return {
      loading: false,
      formConf: {
        fields: []
      }
    };
  },
  mounted: function mounted() {
    this.handlerGetFormConfig(this.formId);
  },
  methods: {
    handlerGetFormConfig: function handlerGetFormConfig(formId) {
      var _this = this;

      // 获取表单配置后生成table列
      this.loading = true;
      var _pram = {
        id: formId
      };
      systemFormConfigApi.getFormConfigInfo(_pram).then(function (data) {
        _this.formConf = JSON.parse(data.content);
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    handlerSubmit: function handlerSubmit(formValue) {
      this.$emit('submit', formValue);
    },
    resetForm: function resetForm(formValue) {
      this.$emit('resetForm', formValue);
    }
  }
};
exports.default = _default;