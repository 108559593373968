var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "100px",
                        inline: true,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "时间选择：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectChange(
                                    _vm.tableFrom.dateLimit
                                  )
                                },
                              },
                              model: {
                                value: _vm.tableFrom.dateLimit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "dateLimit", $$v)
                                },
                                expression: "tableFrom.dateLimit",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择门店：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                filterable: "",
                                placeholder: "请选择",
                                clearable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.storeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "storeId", $$v)
                                },
                                expression: "tableFrom.storeId",
                              },
                            },
                            _vm._l(_vm.storeSelectList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "关键字：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入姓名、电话、订单ID",
                                size: "small",
                                clearable: "",
                              },
                              model: {
                                value: _vm.tableFrom.keywords,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "keywords", $$v)
                                },
                                expression: "tableFrom.keywords",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("cards-data", { attrs: { "card-lists": _vm.cardLists } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "订单号", prop: "orderId", "min-width": "200" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "realName",
                  label: "用户信息",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "推荐人信息", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.spreadInfo.name))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品信息", "min-width": "400" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          { attrs: { trigger: "hover", placement: "right" } },
                          [
                            scope.row.productList &&
                            scope.row.productList.length
                              ? _c(
                                  "div",
                                  {
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  _vm._l(
                                    scope.row.productList,
                                    function (val, i) {
                                      return _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass:
                                            "tabBox acea-row row-middle",
                                          staticStyle: {
                                            "flex-wrap": "inherit",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "demo-image__preview mr10",
                                            },
                                            [
                                              _c("el-image", {
                                                attrs: {
                                                  src: val.info.image,
                                                  "preview-src-list": [
                                                    val.info.image,
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "text_overflow" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "tabBox_tit mr10",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      val.info.productName +
                                                        " | "
                                                    ) +
                                                      _vm._s(
                                                        val.info.sku
                                                          ? val.info.sku
                                                          : "-"
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "tabBox_pice" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      "￥" + val.info.price
                                                        ? val.info.price +
                                                            " x " +
                                                            val.info.payNum
                                                        : "-"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.productList &&
                            scope.row.productList.length
                              ? _c(
                                  "div",
                                  { staticClass: "pup_card" },
                                  _vm._l(
                                    scope.row.productList,
                                    function (val, i) {
                                      return _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass:
                                            "tabBox acea-row row-middle",
                                          staticStyle: {
                                            "flex-wrap": "inherit",
                                          },
                                        },
                                        [
                                          _c("div", {}, [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "tabBox_tit mr10",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    val.info.productName + " | "
                                                  ) +
                                                    _vm._s(
                                                      val.info.sku
                                                        ? val.info.sku
                                                        : "-"
                                                    )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "tabBox_pice" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    "￥" + val.info.price
                                                      ? val.info.price +
                                                          " x " +
                                                          val.info.payNum
                                                      : "-"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "payPrice",
                  label: "实际支付",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "clerkName",
                  label: "核销员",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "storeName",
                  label: "核销门店",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "支付状态", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("paidFilter")(scope.row.paid))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.statusStr.value))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "下单时间",
                  "min-width": "150",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }