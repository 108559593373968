"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _order = require("@/api/order");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'orderSupplier',
  props: {
    orderIds: String
  },
  data: function data() {
    return {
      formItem: {
        supplier: ''
      },
      modals: false,
      express: [],
      exportTempList: [],
      tempImg: '',
      rules: {},
      expressType: 'normal'
    };
  },
  mounted: function mounted() {},
  methods: {
    // 快递公司选择
    onChangeExport: function onChangeExport(val) {
      this.formItem.expressTempId = '';
      if (this.formItem.expressRecordType === '2') this.exportTemp(val);
    },
    supplierOptionList: function supplierOptionList() {
      var _this = this;

      var param = {
        "configKey": "box_product_supplier"
      };
      (0, _order.boxConfigOptionListApi)(param).then(function (res) {
        _this.express = res;
      });
    },
    // 提交
    putSend: function putSend(name) {
      var _this2 = this;

      console.info(this.orderIds);
      this.formItem.ids = this.orderIds;

      if (this.orderIds != "" && this.formItem.supplier != "") {
        (0, _order.setSupplierApi)(this.formItem).then(function (res) {
          var that = _this2;
          that.$message.success('分配成功');
          that.modals = false;
          that.$emit('setSupplier');
        });
      } else {
        this.$message.error('请选择供应商');
      }
    },
    handleClose: function handleClose() {
      this.cancel('formItem');
    },
    cancel: function cancel(name) {
      this.modals = false;
      this.$refs[name].resetFields();
      this.formItem.type = '1';
      this.formItem.expressRecordType = '1';
    }
  }
};
exports.default = _default;