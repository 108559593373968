"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.allDeleteApi = allDeleteApi;
exports.orderListApi = orderListApi;
exports.storeDeleteApi = storeDeleteApi;
exports.storeGetCountApi = storeGetCountApi;
exports.storeInfoApi = storeInfoApi;
exports.storeListApi = storeListApi;
exports.storeRecoveryApi = storeRecoveryApi;
exports.storeSaveApi = storeSaveApi;
exports.storeStaffDeleteApi = storeStaffDeleteApi;
exports.storeStaffInfoApi = storeStaffInfoApi;
exports.storeStaffListApi = storeStaffListApi;
exports.storeStaffSaveApi = storeStaffSaveApi;
exports.storeStaffUpdateApi = storeStaffUpdateApi;
exports.storeStaffUpdateStatusApi = storeStaffUpdateStatusApi;
exports.storeUpdateApi = storeUpdateApi;
exports.storeUpdateStatusApi = storeUpdateStatusApi;
exports.userListApi = userListApi;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * 提货点分页列表
 * @param pram
 */
function storeListApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/list',
    method: 'get',
    params: data
  });
}
/**
 * 提货点数量
 * @param pram
 */


function storeGetCountApi() {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/getCount',
    method: 'get'
  });
}
/**
 * 提货点门店状态
 * @param pram
 */


function storeUpdateStatusApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/update/status',
    method: 'get',
    params: data
  });
}
/**
 * 提货点门店刪除
 * @param pram
 */


function storeDeleteApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/delete',
    method: 'get',
    params: data
  });
}
/**
 * 提货点门店回收站刪除
 * @param pram
 */


function allDeleteApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/completely/delete',
    method: 'get',
    params: params
  });
}
/**
 * 提货点添加
 * @param pram
 */


function storeSaveApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/save',
    method: 'post',
    data: data
  });
}
/**
 * 提货点详情
 * @param pram
 */


function storeInfoApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/info',
    method: 'get',
    params: data
  });
}
/**
 * 提货点修改
 * @param pram
 */


function storeUpdateApi(data, id) {
  // const param = ;
  return (0, _request.default)({
    url: '/manghe/admin/system/store/update',
    method: 'post',
    params: {
      id: id
    },
    data: data
  });
}
/**
 * 提货点恢复
 * @param pram
 */


function storeRecoveryApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/recovery',
    method: 'get',
    params: params
  });
}
/**
 * 核销员分页列表
 * @param pram
 */


function storeStaffListApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/staff/list',
    method: 'get',
    params: data
  });
}
/**
 * 核销员添加
 * @param pram
 */


function storeStaffSaveApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/staff/save',
    method: 'POST',
    params: data
  });
}
/**
 * 核销员删除
 * @param pram
 */


function storeStaffDeleteApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/staff/delete',
    method: 'get',
    params: data
  });
}
/**
 * 核销员编辑
 * @param pram
 */


function storeStaffUpdateApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/staff/update',
    method: 'POST',
    params: data
  });
}
/**
 * 核销员编辑
 * @param pram
 */


function storeStaffInfoApi(id) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/staff/info',
    method: 'get',
    params: id
  });
}
/**
 * 核销员编辑
 * @param pram
 */


function storeStaffUpdateStatusApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/staff/update/status',
    method: 'get',
    params: data
  });
}
/**
 * 核销员编辑
 * @param pram
 */


function userListApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/wechat/user/list',
    method: 'get',
    params: data
  });
}
/**
 * 核销订单
 * @param pram
 */


function orderListApi(params) {
  return (0, _request.default)({
    url: '/manghe/admin/system/store/order/list',
    method: 'post',
    params: params
  });
}