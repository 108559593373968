"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cityInfo = cityInfo;
exports.cityList = cityList;
exports.cityListTree = cityListTree;
exports.cityUpdate = cityUpdate;
exports.expressDelete = expressDelete;
exports.expressInfo = expressInfo;
exports.expressList = expressList;
exports.expressSave = expressSave;
exports.expressSyncApi = expressSyncApi;
exports.expressUpdate = expressUpdate;
exports.expressUpdateShow = expressUpdateShow;
exports.shippingDetete = shippingDetete;
exports.shippingFree = shippingFree;
exports.shippingRegion = shippingRegion;
exports.shippingSave = shippingSave;
exports.shippingTemplatesList = shippingTemplatesList;
exports.shippingUpdate = shippingUpdate;
exports.templateDetailApi = templateDetailApi;
exports.updateStatus = updateStatus;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// 城市列表
function cityList(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/city/list',
    method: 'get',
    params: _objectSpread({}, data)
  });
} // 城市列表


function cityListTree() {
  return (0, _request.default)({
    url: '/manghe/admin/system/city/list/tree',
    method: 'get'
  });
} // 城市修改状态


function updateStatus(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/city/update/status',
    method: 'post',
    params: _objectSpread({}, data)
  });
} // 城市修改


function cityUpdate(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/city/update',
    method: 'post',
    params: _objectSpread({}, data)
  });
} // 城市详情


function cityInfo(data) {
  return (0, _request.default)({
    url: '/manghe/admin/system/city/info',
    method: 'get',
    params: _objectSpread({}, data)
  });
} // 物流公司列表


function expressList(data) {
  return (0, _request.default)({
    url: '/manghe/admin/express/list',
    method: 'get',
    params: _objectSpread({}, data)
  });
} // 同步物流公司


function expressSyncApi() {
  return (0, _request.default)({
    url: '/manghe/admin/express/sync/express',
    method: 'post'
  });
} // 物流公司修改状态


function expressUpdateShow(data) {
  return (0, _request.default)({
    url: '/manghe/admin/express/update/show',
    method: 'post',
    data: data
  });
} // 物流公司 编辑


function expressUpdate(data) {
  return (0, _request.default)({
    url: '/manghe/admin/express/update',
    method: 'post',
    data: data
  });
} // 新增物流公司


function expressSave(data) {
  return (0, _request.default)({
    url: '/manghe/admin/express/save',
    method: 'post',
    params: _objectSpread({}, data)
  });
} // 删除物流公司


function expressDelete(data) {
  return (0, _request.default)({
    url: '/manghe/admin/express/delete',
    method: 'GET',
    params: _objectSpread({}, data)
  });
} // 物流运费模板列表


function shippingTemplatesList(data) {
  return (0, _request.default)({
    url: '/manghe/admin/express/shipping/templates/list',
    method: 'get',
    params: _objectSpread({}, data)
  });
} // 物流运费模板详情


function templateDetailApi(data) {
  return (0, _request.default)({
    url: '/manghe/admin/express/shipping/templates/info',
    method: 'get',
    params: _objectSpread({}, data)
  });
} // 物流运费模板包邮


function shippingFree(data) {
  return (0, _request.default)({
    url: '/manghe/admin/express/shipping/free/list',
    method: 'get',
    params: _objectSpread({}, data)
  });
} // 物流运费模板不包邮


function shippingRegion(data) {
  return (0, _request.default)({
    url: 'admin/express/shipping/region/list',
    method: 'get',
    params: _objectSpread({}, data)
  });
} // 物流运费模板新增


function shippingSave(data) {
  return (0, _request.default)({
    url: 'admin/express/shipping/templates/save',
    method: 'post',
    data: data
  });
} // 物流运费模板更新


function shippingUpdate(data, id) {
  return (0, _request.default)({
    url: 'admin/express/shipping/templates/update',
    method: 'post',
    data: data,
    params: _objectSpread({}, id)
  });
} // 物流运费模板删除


function shippingDetete(data) {
  return (0, _request.default)({
    url: 'admin/express/shipping/templates/delete',
    method: 'get',
    params: data
  });
} // 物流公司详情


function expressInfo(data) {
  return (0, _request.default)({
    url: 'admin/express/info',
    method: 'get',
    params: _objectSpread({}, data)
  });
}