"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addRole = addRole;
exports.delRole = delRole;
exports.getInfo = getInfo;
exports.getRoleList = getRoleList;
exports.updateRole = updateRole;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function addRole(pram) {
  var data = {
    level: pram.level,
    roleName: pram.roleName,
    status: pram.status,
    rules: pram.rules.join(',')
  }; // data.rules = pram.rules.join(',')

  return (0, _request.default)({
    url: '/manghe/admin/system/role/save',
    method: 'POST',
    data: data
  });
}

function delRole(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/manghe/admin/system/role/delete',
    method: 'GET',
    params: data
  });
}

function getInfo(pram) {
  var data = {
    ids: pram.id
  };
  return (0, _request.default)({
    url: '/manghe/admin/system/role/info',
    method: 'get',
    params: data
  });
}

function getRoleList(pram) {
  var data = {
    createTime: pram.createTime,
    updateTime: pram.updateTime,
    level: pram.level,
    page: pram.page,
    limit: pram.limit,
    roleName: pram.roleName,
    rules: pram.rules,
    status: pram.status
  };
  return (0, _request.default)({
    url: '/manghe/admin/system/role/list',
    method: 'get',
    params: data
  });
}

function updateRole(pram) {
  var data = {
    id: pram.id,
    level: pram.level,
    roleName: pram.roleName,
    rules: pram.rules.join(','),
    status: pram.status
  };
  return (0, _request.default)({
    url: '/manghe/admin/system/role/update',
    method: 'post',
    params: {
      id: pram.id
    },
    data: data
  });
}